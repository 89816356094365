import React from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'
import { Heading, Paragraph } from '../components/typography'
import { Image } from '../components/Image'
import Section from '../components/Section'
import { ITeamMembers } from '../graphql/sections/teamMembers'
import { ICopy } from '../graphql/modules/copy'

interface IProps {
  data: ITeamMembers
}

const TeamMembersContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'row', 'row'],
    flexWrap: ['none', 'wrap', 'wrap'],
    justifyContent: ['center', 'space-around', 'space-around'],
    alignItems: ['center', 'flex-start', 'flex-start'],
    padding: ['15px 0 0 0', '30px 0 0 0', '30px 0 0 0'],
  })}
`

const TeamMember = styled.div`
  ${mq({
    maxWidth: ['320px', '260px', '414px'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: ['15px 0', '30px 9px', '30px 13px'],
  })}
`

const titleStyles = {
  textAlign: 'center',
  margin: '0',
}

const imageStyles = {
  height: ['241px', '195px', '311px'],
  width: ['320px', '260px', '414px'],
}

const nameStyles = {
  fontSize: '24px',
  fontWeight: 'bold',
  lineHeight: '1.42',
  textAlign: 'left',
  margin: ['15px 0 10px 0', '20px 0 10px 0', '20px 0 10px 0'],
}

const positionStyles = {
  fontSize: '18px',
  lineHeight: '1.44',
  textAlign: 'left',
  margin: '0',
}

const TeamMembers: React.FC<{ team: ICopy[] }> = ({ team }) => {
  return (
    <>
      {team.map(({ title: name, text, image }) => {
        const position = text?.text ?? ''
        const fluidImg = image?.image.gatsbyImageData
        const altText = image?.alternateText.alternateText ?? ''

        return (
          <TeamMember key={name}>
            <Image fluid={fluidImg} alt={altText} customStyles={imageStyles} />
            <Heading as="h5" styleOverrides={nameStyles}>
              {name}
            </Heading>
            <Paragraph styleOverrides={positionStyles}>{position}</Paragraph>
          </TeamMember>
        )
      })}
    </>
  )
}

export const TeamMembersSection: React.FC<IProps> = ({
  data: { title, team },
}) => {
  return (
    <Section title={title}>
      <Heading as="h2" styleOverrides={titleStyles}>
        {title}
      </Heading>
      <TeamMembersContainer>
        <TeamMembers team={team} />
      </TeamMembersContainer>
    </Section>
  )
}
