import React from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'

import mq from '../styles/breakpoints'

import { Heading } from '../components/typography'
import BlogPost from '../components/BlogPost'
import Section from '../components/Section'
import { IBlogsSection } from '../graphql/sections/blogs'

interface IProps {
  data: IBlogsSection
}

const BlogsContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: ['center', 'space-evenly', 'space-evenly'],
    marginTop: ['28px', '28px', '32px'],
  })}
`

export const BlogsSection: React.FC<IProps> = ({ data }) => {
  const blogs = get(data, 'blogs', [])
  const title = get(data, 'title', '')
  const relatedBlogs = get(data, 'relatedBlogs', [])
  //blogs array comes from the Blogs section component in contentful. When less than three blogs are chosen, the rest can be filled in
  //by the related blogs, which are pulled from Blog Categories on the Blog section. Related blogs will be empty when this component is used on
  //a page that does not contain a Blog post.
  const displayBlogs = [...blogs]
  if (displayBlogs.length < 3 && relatedBlogs.length > 0) {
    displayBlogs.push(...relatedBlogs.slice(0, 3 - displayBlogs.length))
  }

  return (
    <Section title={title}>
      <Heading as="h2">{title}</Heading>
      <BlogsContainer>
        {displayBlogs.map(blog => (
          <BlogPost key={blog.title} blog={blog} />
        ))}
      </BlogsContainer>
    </Section>
  )
}
