import React from 'react'
import { Document } from '@contentful/rich-text-types'
import { RichTextData } from '../components/RichText'

interface INode {
  content?: INode[]
  value?: string
}

function traverseLeafNodes(nodes: INode[]): number {
  let count = 0
  nodes.forEach(({ content, value }) => {
    if (content) count += traverseLeafNodes(content)
    if (value) {
      count += value.replace(/[^\w ]/g, '').split(/\s+/).length
    }
  })
  return count
}

export const useReadTime = (richTextData: RichTextData) => {
  const wordCount = React.useMemo(() => {
    const richText = (JSON.parse(richTextData.raw) as Document).content
    let curCount = 0
    richText.forEach(({ content }) => {
      curCount += traverseLeafNodes(content)
    })
    return curCount
  }, [richTextData])
  return Math.floor(wordCount / 228) + 1
}
