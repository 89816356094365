import React from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'

import { Heading, Paragraph } from '../components/typography'
import { Image } from '../components/Image'
import { Section } from '../components/Section'
import { IStatistics } from '../graphql/sections/statistics'
import { ICopy } from '../graphql/modules/copy'

interface IProps {
  data: IStatistics
}
const customSectionStyles = {
  padding: ['35px 41px', '50px 36px', '50px 163px'],
}

const StatisticsContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'row', 'row'],
    flexWrap: 'wrap',
    justifyContent: ['none', 'space-around', 'space-between'],
    marginTop: ['25px', '25px', '50px'],
    width: '100%',
  })}
`

const Statistic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const imageStyles = {
  width: ['190px', '190px', '240px'],
  height: 'inherit',
  margin: '0',
}

const statisticTitleStyles = { margin: ['0', '15px 0', '15px 0'] }

const paragraphStyles = {
  margin: ['0 0 30px 0', '0 0 50px 0', ''],
  textAlign: 'center',
}

const Statistics: React.FC<{ data: ICopy[] }> = ({ data }) => {
  return (
    <>
      {data.map(({ image, text, title }, index) => {
        const altText = image?.alternateText.alternateText ?? ''
        const fluidImg = image?.image.gatsbyImageData
        const statisticText = text?.text

        return (
          <Statistic key={index}>
            <Image fluid={fluidImg} alt={altText} customStyles={imageStyles} />
            <Heading as="h2" styleOverrides={statisticTitleStyles}>
              {title}
            </Heading>
            <Paragraph styleOverrides={paragraphStyles}>
              {statisticText}
            </Paragraph>
          </Statistic>
        )
      })}
    </>
  )
}

export const StatisticsSection: React.FC<IProps> = ({
  data: { title, statistics },
}) => {
  return (
    <Section title={title} customStyles={customSectionStyles}>
      <Heading as="h2">{title}</Heading>
      <StatisticsContainer>
        <Statistics data={statistics} />
      </StatisticsContainer>
    </Section>
  )
}
