import React from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'

import {
  Heading,
  Paragraph,
  Superscript,
  Markdown,
} from '../components/typography'
import { Image } from '../components/Image'
import Section from '../components/Section'
import { Card } from '../components/Card'
import { IThreeBlocksData } from '../graphql/sections/threeBlocks'
import { ICard } from '../graphql/modules/card'
import { ICopy } from '../graphql/modules/copy'

interface IProps {
  data: IThreeBlocksData
}

interface IBlock {
  data: ICopy
}

const Blocks = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'row', 'row'],
    flexWrap: 'wrap',
    justifyContent: ['center', 'space-around', 'space-evenly'],
    marginTop: ['39px', '0', '30px'],
    width: '100%',
  })}
`

const BlockContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: ['100%', '100%', '414px'],
    padding: ['0', '0', '0 12px'],
    marginTop: ['0', '0', '30px'],
  })}
`

const imageStyles = {
  width: ['190px', '190px', '240px'],
  height: undefined, //['140px', '140px', '180px'],
  overflow: 'hidden',
}

const blockTitleStyles = {
  whiteSpace: 'pre-wrap',
  margin: '10px 0',
}

const titleStyles = {
  paddingBottom: ['0', '10px', '0'],
}

const paragraphStyles = {
  textAlign: 'left',
  margin: ['0 0 40px 0', '0 0 25px 0', '0'],
}

const Block: React.FC<IBlock> = ({ data }) => {
  const { title, text, image, contentful_id, __typename } = data
  const fluidImg = image && image.image.gatsbyImageData
  const altText = image ? image.alternateText.alternateText : ''
  const copyText = text ? text.text : null

  return (
    <BlockContainer key={title}>
      {fluidImg && (
        <Image fluid={fluidImg} alt={altText} customStyles={imageStyles} />
      )}
      <Heading as="h3" styledAs="h5" styleOverrides={blockTitleStyles}>
        {title && <Superscript>{title}</Superscript>}
      </Heading>
      {copyText && (
        <Markdown
          contentfulId={contentful_id}
          typeName={__typename}
          customStyles={{ paragraph: paragraphStyles }}
          withSuperscript
        >
          {copyText}
        </Markdown>
      )}
    </BlockContainer>
  )
}

const ThreeBlocksSection: React.FC<IProps> = ({
  data: { title, threeBlocks },
}) => {
  return (
    <Section title={title}>
      <Heading as="h2" styleOverrides={titleStyles}>
        {title}
      </Heading>
      <Blocks>
        {threeBlocks.map(({ __typename, ...data }) => {
          if (__typename === 'ContentfulCopy')
            return <Block key={data.contentful_id} data={data as ICopy} />
          if (__typename === 'ContentfulCard')
            return <Card key={data.contentful_id} data={data as ICard} />
          return null
        })}
      </Blocks>
    </Section>
  )
}

export default ThreeBlocksSection
