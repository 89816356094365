import React from 'react'
import get from 'lodash/get'

import { Heading, Paragraph } from '../components/typography'
import Section from '../components/Section'
import AppStoreBadges from '../components/AppStoreBadges'
import { IAppStoresSection } from '../graphql/sections/appStores'

interface IProps {
  data: IAppStoresSection
}

const paragraphStyles = {
  maxWidth: 'none',
  margin: ['10px 0 20px 0', '25px 20px', '25px 110px 50px 108px'],
  textAlign: ['left', 'left', 'center'],
}

export const AppStoresSection: React.FC<IProps> = ({
  data: {
    title: sectionTitle,
    block: { title, text },
  },
}) => {
  const sectionText = text?.text

  return (
    <Section title={sectionTitle}>
      {title && <Heading as="h2">{title}</Heading>}
      {sectionText && (
        <Paragraph styleOverrides={paragraphStyles}>{sectionText}</Paragraph>
      )}
      <AppStoreBadges />
    </Section>
  )
}
