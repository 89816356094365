import React from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'
import { ITrust } from '../graphql/sections/trust'

import { Image } from '../components/Image'
import { RichText } from '../components/RichText'
import { Section } from '../components/Section'

interface IProps {
  data: ITrust
}

const TrustEnhancer = styled.li`
  ${mq({
    display: 'flex',
    maxWidth: ['100%', '100%', '855px'],
    width: ['100%', '100%', '855px'],
    margin: ['0 auto 0 0', '0 auto 0 0', 'auto'],
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: ['40px', '25px', '0'],
  })}
`
const TrustEnhancerSection = styled.ul`
  ${mq({
    display: 'flex',
    width: '100%',
    flexDirection: ['column', 'column', 'row'],
    margin: '0px',
  })}
`

const customSectionStyles = {
  flexDirection: ['column', 'column', 'row'],
  alignItems: 'flex-start',
}

const imageStyles = {
  marginRight: ['13px', '25px', '25px'],
  minWidth: ['36px', '44px', '80px'],
  width: ['36px', '44px', '80px'],
  height: ['36px', '44px', '80px'],
}

const richTextCustomStyles = {
  paragraph: {
    margin: '0',
    lineHeight: ['1.43', '1.43', '1.5'],
    maxWidth: ['80%', '100%', '459px'],
    textAlign: 'left',
  },
}

const TrustSection: React.FC<IProps> = ({ data }) => {
  const {
    title: sectionTitle,
    trustEnhancerOne: trustEnhancerOneRichText,
    trustEnhancerTwo: trustEnhancerTwoRichText,
    trustEnhancerOneImage: {
      image: { gatsbyImageData: trustEnhancerOneImage },
      alternateText: { alternateText: trustEnhancerImageOneAltText },
    },
    trustEnhancerTwoImage: {
      image: { gatsbyImageData: trustEnhancerTwoImage },
      alternateText: { alternateText: trustEnhancerImageTwoAltText },
    },
  } = data

  return (
    <Section title={sectionTitle} customStyles={customSectionStyles}>
      <TrustEnhancerSection>
        <TrustEnhancer>
          <Image
            fluid={trustEnhancerOneImage}
            alt={trustEnhancerImageOneAltText}
            customStyles={imageStyles}
          />
          <RichText
            content={trustEnhancerOneRichText}
            customStyles={richTextCustomStyles}
          />
        </TrustEnhancer>
        <TrustEnhancer>
          <Image
            fluid={trustEnhancerTwoImage}
            alt={trustEnhancerImageTwoAltText}
            customStyles={imageStyles}
          />
          <RichText
            content={trustEnhancerTwoRichText}
            customStyles={richTextCustomStyles}
          />
        </TrustEnhancer>
      </TrustEnhancerSection>
    </Section>
  )
}

export default TrustSection
