import React from 'react'
import styled from '@emotion/styled'

import { mq } from '../styles/breakpoints'

import { Button } from '../components/Button'
import { Image } from '../components/Image'
import { Heading, Superscript, Markdown } from '../components/typography'
import { Section } from '../components/Section'
import { IProducts } from '../graphql/sections/products'
import { ICopy } from '../graphql/modules/copy'
import { getCookieData } from '../helpers/utils'

interface IProps {
  data: IProducts
}

const ProductsContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  })}
`

const Product = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'row', 'row'],
    justifyContent: 'space-evenly',
    alignItems: ['center', 'flex-start', 'center'],
    margin: ['0 0 40px 0', '0 0 50px 0', '22px 0'],
  })}
`

const ProductContent = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: ['center', 'flex-start', 'flex-start'],
    width: ['100%', '50%', '50%'],
    margin: ['15px 0 0 0', 'none', '0 0 0 50px'],
  })}
`

const productImageStyles = {
  width: ['280px', '220px', '500px'],
  height: undefined,
  //height: ['210px', '165px', '375px'],
  //margin: ['10px 0 0 0', 'none', 'none'],
}

const titleStyles = {
  textAlign: ['center', 'left', 'left'],
  margin: '0',
}

const paragraphStyles = {
  textAlign: 'left',
  margin: ['10px 0 20px 0', '15px 0 25px 0', '18px 0 50px 0'],
}

const customButtonStyles = {
  maxWidth: '100%',
}

const Products: React.FC<{ productsData: ICopy[] }> = ({ productsData }) => {
  return (
    <>
      {productsData.map(
        ({ title, text, image, link, contentful_id, __typename }, index) => {
          const productText = text?.text ?? ''
          const altText = image?.alternateText.alternateText ?? ''
          const fluidImg = image?.image.gatsbyImageData
          const buttonLink = link?.link
          const buttonText = link?.linkText
          const buttonName = link?.linkName
          const buttonRel = link?.rel
          const buttonContentfulId = link?.contentful_id ?? null
          const buttonTypeName = link?.__typename ?? null

          const linkCookieData = getCookieData(link)

          return (
            <Product key={index}>
              <Image
                fluid={fluidImg}
                alt={altText}
                customStyles={productImageStyles}
              />
              <ProductContent>
                <Heading as="h3" styledAs="h4" styleOverrides={titleStyles}>
                  {title && <Superscript>{title}</Superscript>}
                </Heading>
                {productText && (
                  <Markdown
                    customStyles={{ paragraph: paragraphStyles }}
                    withSuperscript
                    contentfulId={contentful_id}
                    typeName={__typename}
                  >
                    {productText}
                  </Markdown>
                )}
                <Button
                  to={buttonLink}
                  name={buttonName}
                  rel={buttonRel}
                  linkCookieData={linkCookieData}
                  styleOverrides={customButtonStyles}
                  contentfulId={buttonContentfulId}
                  typeName={buttonTypeName}
                >
                  {buttonText}
                </Button>
              </ProductContent>
            </Product>
          )
        },
      )}
    </>
  )
}

export const ProductsSection: React.FC<IProps> = ({
  data: { title, products },
}) => {
  return (
    <Section title={title}>
      {title && <Heading as="h2">{title}</Heading>}
      <ProductsContainer>
        <Products productsData={products} />
      </ProductsContainer>
    </Section>
  )
}
