import React, { useEffect } from 'react'
import { useSplitClient } from '@splitsoftware/splitio-react'
import { graphql, navigate } from 'gatsby'

import { PageSection } from '../components/PageSectionRenderer'
import { ContentfulPagePaths, TypeNames } from '../constants/enums'
import { usePageContext } from '../context/PageContextProvider'
import { useAnonymousIdContext } from '../context/AnonymousIdContextProvider'
import { useExperiment } from '../experiments/useExperiment'
import { IBlog } from '../graphql/sections/blog'
import { IBlogsSection } from '../graphql/sections/blogs'
import { Sections } from '../graphql/sections/types'
import deriveMetadata from '../helpers/deriveMetadata'
import { sortByDate } from '../helpers/utils'
import { useSendPageViewedEvent } from '../hooks/analytics/useSendPageViewedEvent'
import {
  IPageWithExperimentSectionsSetupResult,
  usePageWithExperimentSectionsSetup,
} from '../hooks/usePageWithExperimentSectionsSetup'
import usePageWithoutExperimentSectionsSetup from '../hooks/usePageWithoutExperimentSectionsSetup'
import { SectionContainer } from '../styles/containerStyles'
import FooterSection from './FooterSection'
import StickyCTASection from './StickyCTASection'

interface IContentfulPageData {
  contentfulPage: {
    sections: Sections[]
    slug: string
    contentful_id: string
    experiment: string | null
  }
}

function addRelatedBlogsToSection(sectionData: IBlogsSection, blogData: IBlog) {
  const { title: currentBlogTitle } = blogData
  const filterMap: Record<string, boolean> = {}
  // get related blogs, flatten the arrays, filter out current blog and duplicates,
  // and sort by date
  const relatedBlogs = (blogData.relatedBlogs || [])
    .map(({ blog }) => blog)
    .flat()
    .filter(blog => {
      const { title } = blog
      if (filterMap[title] || title === currentBlogTitle) return false
      return (filterMap[title] = true)
    })
    .sort((blogOne, blogTwo) => sortByDate(blogOne, blogTwo))
  return { ...sectionData, relatedBlogs }
}

interface IPageAdapterRenderer {
  data: IContentfulPageData
  sections: Sections[]
}

const PageAdapterRenderer: React.FC<IPageAdapterRenderer> = ({ data, sections }) => {
  const { contentful_id: pageContentfulId, slug } = data.contentfulPage

  const hasMetadata = sections.some(section => section.__typename === TypeNames.CONTENTFUL_METADATA)
  !hasMetadata && sections.push(deriveMetadata(sections))

  let blogSection: IBlog
  const hasBlog = sections.some(section => {
    if (section.__typename === TypeNames.CONTENTFUL_BLOG) {
      blogSection = section
      return true
    }
    return false
  })

  const bannerSection: Sections | null =
    sections.find(section => section.__typename === TypeNames.CONTENTFUL_BANNER) ?? null

  return (
    <SectionContainer data-entry-id={pageContentfulId}>
      {bannerSection && (
        <PageSection key={bannerSection.contentful_id ?? `${bannerSection.__typename}`} data={bannerSection} />
      )}
      {sections &&
        sections.map((section, index) => {
          if (section.__typename === TypeNames.CONTENTFUL_BLOGS && hasBlog) {
            section = addRelatedBlogsToSection(section, blogSection)
          }
          if (section.__typename === TypeNames.CONTENTFUL_METADATA) section.slug = slug
          if (section.__typename === TypeNames.CONTENTFUL_BANNER) return

          return <PageSection key={section.contentful_id ?? `${section.__typename}-${index}`} data={section} />
        })}
      <StickyCTASection />
      <FooterSection />
    </SectionContainer>
  )
}

interface IPageAdapterWithExperimentProps {
  data: IContentfulPageData
  experiment: string
  pageSections: Sections[]
}
const PageAdapterWithExperiment: React.FC<IPageAdapterWithExperimentProps> = ({ data, experiment, pageSections }) => {
  const { isReady: isSplitReady } = useSplitClient()
  const { contentful_id: pageContentfulId } = data.contentfulPage
  const { anonymousId, isTimedOut: isAnonymousIdTimedOut } = useAnonymousIdContext()
  const { treatment: treatmentRequested } = useExperiment<string>(experiment)
  const sendPageViewedEvent = useSendPageViewedEvent()

  const { pageContext, setPageContext } = usePageContext()

  const { sections, treatmentSelected, shouldRedirectTo404Page }: IPageWithExperimentSectionsSetupResult =
    usePageWithExperimentSectionsSetup({
      experiment,
      pageSections,
      treatment: treatmentRequested,
    })

  useEffect(() => {
    setPageContext({
      pageContentfulId,
      experiment,
      treatmentRequested,
      treatmentSelected,
      pagePath: window.location.pathname,
      pageUrl: window.location.href,
    })
  }, [setPageContext, pageContentfulId, experiment, treatmentRequested, treatmentSelected])

  useEffect(() => {
    // For PageAdapterWithExperiment, wait for Split treatment to be ready before sending Page Viewed event
    // This prevents sending the event twice; once with initial control and once with the assigned treatment.
    if (isSplitReady && pageContentfulId === pageContext.pageContentfulId) {
      sendPageViewedEvent(pageContentfulId)
    }
  }, [isSplitReady, pageContentfulId, pageContext.pageContentfulId, sendPageViewedEvent])

  // Display empty screen while waiting for anonymousId to be ready and while
  // waiting for Split to be ready. Relevant for SSG.
  if ((!anonymousId && !isAnonymousIdTimedOut) || !isSplitReady) return null

  if (shouldRedirectTo404Page) {
    navigate(ContentfulPagePaths.ERROR_404, { replace: true })
    return null
  }

  return <PageAdapterRenderer data={data} sections={sections} />
}

interface IPageAdapterWithoutExperimentProps {
  data: IContentfulPageData
  pageSections: Sections[]
}
const PageAdapterWithoutExperiment: React.FC<IPageAdapterWithoutExperimentProps> = ({ data, pageSections }) => {
  const { contentful_id: pageContentfulId } = data.contentfulPage
  const { pageContext, setPageContext } = usePageContext()
  const sendPageViewedEvent = useSendPageViewedEvent()

  useEffect(() => {
    setPageContext({
      pageContentfulId,
      experiment: null,
      treatmentRequested: null,
      treatmentSelected: null,
      pagePath: window.location.pathname,
      pageUrl: window.location.href,
    })
  }, [pageContentfulId])

  useEffect(() => {
    if (pageContentfulId === pageContext.pageContentfulId) {
      sendPageViewedEvent(pageContentfulId)
    }
  }, [pageContentfulId, pageContext.pageContentfulId, sendPageViewedEvent])

  const sectionsFiltered = usePageWithoutExperimentSectionsSetup(pageSections)

  return <PageAdapterRenderer data={data} sections={sectionsFiltered} />
}

export interface IPageAdapterProps {
  data: IContentfulPageData
}
const PageAdapter: React.FC<IPageAdapterProps> = ({ data }) => {
  const { sections: pageSections, experiment } = data.contentfulPage
  // The experiment value is dynamic depending on the A/B test - meaning we could have different treatment types for different experiments.
  // Hence, we need to use string for the treatment type vs a specific treatment type like BOOLEAN_EXPERIMENT.
  if (experiment) {
    return <PageAdapterWithExperiment data={data} pageSections={pageSections} experiment={experiment} />
  }

  return <PageAdapterWithoutExperiment data={data} pageSections={pageSections} />
}

export default PageAdapter

export const pageQuery = graphql`
  query ContentfulPage($pageId: String!) {
    contentfulPage(contentful_id: { eq: $pageId }) {
      contentful_id
      slug
      experiment
      sections {
        __typename
        ...AlternatingImageTextBlockFragment
        ...AppStoresFragment
        ...BannerFragment
        ...BlogFragment
        ...BlogsFragment
        ...ExpandableFaqFragment
        ...GenericContentFragment
        ...HeroImageFragment
        ...HeroFragment
        ...LinksListFragment
        ...MetadataFragment
        ...MultipleBlocksFragment
        ...OneBlockFragment
        ...OneLinkFragment
        ...PageNavFragment
        ...PageVariationFragment
        ...PartnersFragment
        ...ProductComparisonTableFragment
        ...ProductDetailsListFragment
        ...ProductsFragment
        ...ReviewsFragment
        ...RichTextFragment
        ...StatisticsFragment
        ...TeamMembersFragment
        ...ThreeBlocksFragment
        ...TrustFragment
      }
    }
  }
`
