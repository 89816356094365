export const CONTROL_TREATMENT = 'control'

export enum BOOLEAN_EXPERIMENT {
  on = 'on',
  off = 'off',
  control = 'control',
}

// The Generic type `TreatmentType` here is constrained to be a string or any type that is inherently a string
// Hence, when using ExperimentDetails ensure the `TreatmentType` is a string or a type that is inherently a string
export type ExperimentDetails<TreatmentType extends string> = {
  treatment: TreatmentType
  config: Record<string, string> | null
}
