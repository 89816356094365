import styled from '@emotion/styled'

import { COLORS } from '@borrowell/bw-styles'
import mq from '../styles/breakpoints'
import facepaint from 'facepaint'

interface IProps {
  isOpen: boolean
  customStyles?: facepaint.Arg
}

export const ExpandIcon = styled.div<IProps>(({ isOpen, customStyles }) =>
  mq({
    position: 'relative',
    alignSelf: 'center',
    transition: 'transform 0.5s ease',
    width: '20px',
    height: '3px',
    backgroundColor: COLORS.PURPLE['700'],
    transform: isOpen ? 'rotate(180deg)' : '',
    borderRadius: '1px',
    '&:before': {
      display: 'block',
      content: '""',
      width: '20px',
      height: '3px',
      transform: isOpen ? 'rotate(180deg)' : 'rotate(90deg)',
      transition: 'all 0.5s ease',
      backgroundColor: COLORS.PURPLE['700'],
      borderRadius: '1px',
    },
    ...customStyles,
  })
)
