import styled from '@emotion/styled'
import get from 'lodash/get'
import React, { useEffect, useReducer, useState } from 'react'

import { COLORS } from '@borrowell/bw-styles'
import mortgageCalculatorService from '../services/mortgageCalculatorService'
import mq from '../styles/breakpoints'

import { IconPosition, Input } from '../components/Input'
import Section from '../components/Section'
import { ChangeEventWithMocks } from '../components/commonTypes'
import { Heading } from '../components/typography'
import { useAriaLiveAnnouncement } from '../hooks/useAriaLiveAnnouncement'

interface IProps {
  dataJson: string
  contentfulId: string | null
  typeName: string | null
}

export interface IMortgageCalculator {
  title: string
}

type InputConfig = {
  label: string
  stateKey: keyof IReducerState
  errorMessage?: string
  type: string
  icon?: string
  iconPosition?: IconPosition
}
interface IReducerState {
  amount: string
  downPayment: string
  term: string
  rate: string
  amortization: string
  amountValid: boolean
  downPaymentValid: boolean
  termValid: boolean
  rateValid: boolean
  amortizationValid: boolean
  // Computed Values
  totalMortgageAmount: string
  totalPayment: string
  interestPaid: string
  principalPaid: string
  balance: string
}

const customSectionStyles = {
  width: ['100%', '50%', '50%'],
  margin: '20px auto',
  padding: ['0 20px', '0 20px', '0'],
}

const InputSection = styled.dd`
  ${mq({
    width: ['100%', '100%', '40%'],
    display: ['block', 'block', 'inline-block'],
    border: '0px',
    borderRadius: '8px',
    height: ['50px', '50px', '48px'],
    fontSize: ['16px', '16px', '20px'],
    fontFamily: 'Lato',
    paddingLeft: ['0px', '0px', '40px'],
    textAlign: ['center', 'center', 'left'],
    margin: 0,
  })}
`

const Subtitle = styled.dt`
  ${mq({
    width: ['100%', '100%', '50%'],
    fontSize: ['16px', '16px', '20px'],
    color: COLORS.NEUTRAL.COOL['600'],
    fontFamily: 'Lato',
    fontWeight: '400',
    display: ['block', 'block', 'inline-block'],
    marginBottom: ['10px', '10px', '0px'],
    textAlign: ['center', 'center', 'left'],
    verticalAlign: 'top',
  })}
`

const Container = styled.dl`
  ${mq({
    width: ['100%', '100%', '80%'],
    margin: ['0', '0', '0 auto'],
    paddingTop: '20px',
    textAlign: 'center',
  })}
`

const Form = styled.form`
  ${mq({
    width: ['100%', '', '80%'],
    textAlign: 'center',
  })}
`

const SubmitButton = styled.input`
  ${mq({
    width: ['100%', '100%', '200px'],
    maxWidth: ['300px', 'none', 'none'],
    margin: '20px auto',
    borderRadius: '30px',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '900',
    lineHeight: '1.44',
    color: COLORS.PURPLE['700'],
    borderStyle: 'none',
    backgroundColor: COLORS.YELLOW['400'],
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 55px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ':hover': {
      backgroundColor: COLORS.YELLOW['300'],
    },
  })}
`

const customTitleStyles = {
  margin: '0 0 1.45rem',
}

const initialState: IReducerState = {
  // Input Values
  amount: '200,000',
  downPayment: '40,000',
  term: '5',
  rate: '5',
  amortization: '20',
  // Error States
  amountValid: true,
  downPaymentValid: true,
  termValid: true,
  rateValid: true,
  amortizationValid: true,
  // Computed Values
  totalMortgageAmount: '$   0',
  totalPayment: '$   0',
  interestPaid: '$   0',
  principalPaid: '$   0',
  balance: '$   0',
}

// TODO: Define types for this function
const calculateMortgageValues = ({ amount, downPayment, term, rate, amortization }: Record<string, any>) => {
  const calculatedValues = {
    totalPayment: mortgageCalculatorService.getPayment({
      amount,
      downPayment,
      term,
      rate,
      amortization,
    }),
    interestPaid: mortgageCalculatorService.getInterestPaid({
      amount,
      downPayment,
      term,
      rate,
      amortization,
    }),
    principalPaid: mortgageCalculatorService.getPrincipalPaid({
      amount,
      downPayment,
      term,
      rate,
      amortization,
    }),
    balance: mortgageCalculatorService.getBalance({
      amount,
      downPayment,
      term,
      rate,
      amortization,
    }),
    totalMortgageAmount: mortgageCalculatorService.getMortgageAmount({
      amount,
      downPayment,
    }),
  }

  return calculatedValues
}

/**
 * Validation Regexes
 */
const AMOUNT_REGEX = /^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\.[0-9][0-9])?$/
const RATE_REGEX = /^\d+(\.\d{1,2})?$/
const CURRENCY_REGEX = /,|\$/g
const NUMBER_ONLY_REGEX = /^\d+$/

// TODO improve reducer definition to avoid type casting
const reducer = (state: IReducerState, action: { type: string; payload?: string | boolean }) => {
  const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })

  switch (action.type) {
    case 'amountValid':
      action.payload =
        (action.payload as string).match(AMOUNT_REGEX) !== null &&
        Number(state.amount.replace(CURRENCY_REGEX, '')) >= Number(state.downPayment.replace(CURRENCY_REGEX, ''))
      break
    case 'downPaymentValid':
      action.payload =
        (action.payload as string).match(AMOUNT_REGEX) !== null &&
        Number(state.downPayment.replace(CURRENCY_REGEX, '')) <= Number(state.amount.replace(CURRENCY_REGEX, '')) &&
        Number(state.amount.replace(CURRENCY_REGEX, '')) * 0.05 <= Number(state.downPayment.replace(CURRENCY_REGEX, ''))
      break
    case 'termValid':
      action.payload =
        (action.payload as string).match(NUMBER_ONLY_REGEX) !== null && Number(state.amortization) >= Number(state.term)
      break
    case 'rateValid':
      action.payload = (action.payload as string).match(RATE_REGEX) !== null
      break
    case 'amortizationValid':
      action.payload =
        (action.payload as string).match(NUMBER_ONLY_REGEX) !== null && Number(state.amortization) >= Number(state.term)
      break
    case 'calculate':
      return {
        ...state,
        ...calculateMortgageValues(state),
      }
    default:
      break
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (formatter.format(state.amount).match(AMOUNT_REGEX)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const amount = formatter.format(state.amount)
    state.amount = amount
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (formatter.format(state.downPayment).match(AMOUNT_REGEX)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const downPayment = formatter.format(state.downPayment)
    state.downPayment = downPayment
  }

  return {
    ...state,
    [action.type]: action.payload,
  }
}

const INPUTS_CONFIG: InputConfig[] = [
  {
    label: 'Amount',
    stateKey: 'amount',
    type: 'number',
    icon: '$',
    iconPosition: IconPosition.LEFT,
  },
  {
    label: 'Down Payment',
    stateKey: 'downPayment',
    errorMessage: 'Invalid down payment',
    type: 'number',
    icon: '$',
    iconPosition: IconPosition.LEFT,
  },
  { label: 'Term (Years)', stateKey: 'term', type: 'number' },
  {
    label: 'Rate',
    stateKey: 'rate',
    type: 'number',
    icon: '%',
    iconPosition: IconPosition.RIGHT,
  },
  { label: 'Amortization (Years)', stateKey: 'amortization', type: 'number' },
]

export const MortgageCalculatorSection: React.FC<IProps> = ({ dataJson, contentfulId, typeName }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [shouldAnnounce, setShouldAnnounce] = useState(false)

  const { liveRegion, ariaAnnounce } = useAriaLiveAnnouncement({
    options: { delay: 500 },
  })

  const data = JSON.parse(dataJson).componentData as IMortgageCalculator
  const title = get(data, 'title')
  /**
   * @todo fix the submission for the form
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (state.amountValid && state.downPaymentValid && state.termValid && state.rateValid && state.amortizationValid) {
      dispatch({ type: 'calculate' })
      setShouldAnnounce(true)
    }
  }

  const renderInputs = (inputConfigs: InputConfig[] = []) =>
    inputConfigs.map(({ label, stateKey, errorMessage, icon, iconPosition }) => (
      <Input
        key={stateKey}
        label={label}
        id={stateKey}
        value={state[stateKey] as string}
        icon={icon}
        iconPosition={iconPosition}
        onChange={(e: ChangeEventWithMocks) =>
          dispatch({
            type: stateKey,
            payload: e.target.value,
          })
        }
        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
          dispatch({
            type: `${stateKey}Valid`,
            payload: e.currentTarget.value,
          })
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        hasError={!state[`${stateKey}Valid`]}
        errorHint={errorMessage || `Invalid ${stateKey}`}
      />
    ))

  useEffect(() => {
    if (shouldAnnounce) {
      ariaAnnounce(
        `Monthly Payment: ${state.totalPayment}\nMortgage Amount: ${state.totalMortgageAmount}\nPrincipal Paid for Term: ${state.principalPaid}\nInterest Cost for Term: ${state.interestPaid}\nBalance at End of Term: ${state.balance}`,
      )
      setShouldAnnounce(false)
    }
  }, [shouldAnnounce])

  return (
    <Section title={title} customStyles={customSectionStyles}>
      <Form onSubmit={handleSubmit}>
        <Heading as="h2" styledAs="h4" styleOverrides={customTitleStyles}>
          Mortgage Info
        </Heading>
        {renderInputs(INPUTS_CONFIG)}

        <SubmitButton type="submit" value="Calculate" />
      </Form>
      <hr style={{ width: '100%' }} />
      <Container>
        <Heading as="h3" styledAs="h4" styleOverrides={customTitleStyles}>
          Mortgage Payment
        </Heading>
        {liveRegion}
        <Subtitle>Monthly Payment</Subtitle>
        <InputSection id="payment1">{state.totalPayment}</InputSection>
        <Subtitle>Mortgage Amount</Subtitle>
        <InputSection id="mortgage_amount1">{state.totalMortgageAmount}</InputSection>
        <Subtitle>Principal Paid for Term</Subtitle>
        <InputSection id="principal_paid_first_term1">{state.principalPaid}</InputSection>
        <Subtitle>Interest Cost for Term</Subtitle>
        <InputSection id="interest_paid_first_term1">{state.interestPaid}</InputSection>
        <Subtitle>Balance at End of Term</Subtitle>
        <InputSection id="principal_paid_first_term1">{state.balance}</InputSection>
      </Container>
    </Section>
  )
}
