import React from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'

import mq from '../styles/breakpoints'

import Section from '../components/Section'
import RichText from '../components/RichText'

const defaultCustomStyles = {
  padding: ['40px 0', '60px 0', '45px 0'],
}

const defaultRichTextContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxWidth: ['', '530px', '680px'],
  width: '100%',
  padding: ['0 20px', '0', '0'],
}

const defaultRichTextCustomStyles = {
  paragraph: {
    margin: '12px 0 25px 0',
    textAlign: 'left',
  },
  'heading-1': {
    margin: 'auto',
    textAlign: 'left',
  },
  'heading-2': {
    textAlign: 'left',
  },
  'heading-3': {
    textAlign: 'left',
  },
  'heading-4': {
    textAlign: 'left',
  },
  'heading-5': {
    textAlign: 'left',
  },
}

const RichTextSection = ({
  data = {},
  customRichTextStyles = {},
  customRichTextContentStyles = {},
  customSectionStyles = defaultCustomStyles,
  richTextContent = [],
}) => {
  const richText =
    richTextContent.length === 0 ? get(data, 'text', '') : richTextContent

  const richTextStyles = {
    ...defaultRichTextCustomStyles,
    ...customRichTextStyles,
  }

  const richTextContentStyles = {
    ...defaultRichTextContentStyles,
    ...customRichTextContentStyles,
  }

  const RichTextContent = styled.div`
    ${mq(richTextContentStyles)}
  `
  const sectionTitle = get(data, 'title')

  return (
    <Section title={sectionTitle} customStyles={customSectionStyles}>
      <RichTextContent>
        <RichText content={richText} customStyles={richTextStyles} />
      </RichTextContent>
    </Section>
  )
}

export default RichTextSection
