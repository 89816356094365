import styled from '@emotion/styled'
import React, { useMemo, useState } from 'react'

import { Checkbox } from '../components/Checkbox'
import { Input } from '../components/Input'
import { IMockedChangeEvent } from '../components/commonTypes'
import { Paragraph } from '../components/typography'
import { IWaitListFormData } from '../graphql/sections/waitListForm'
import { isValidEmail } from '../helpers/validations'
import { mq } from '../styles'

import { COLORS } from '@borrowell/bw-styles'
import checkIcon from '../images/circle-checkmark-green.svg'
import { useSendButtonClickedEvent } from './../hooks/analytics/useSendButtonClickedEvent'

interface IProps {
  data: IWaitListFormData
}

interface IField {
  id: string
  value: string
  isDirty: boolean
}

interface IFields {
  email: IField
  agreement: IField
}

const ComponentWrapper = styled.div(
  mq({
    marginBottom: [60, 0, 100],
    width: '100%',
  }),
)

const Form = styled.form`
  width: 100%;
`

const SubmitButton = styled.input<{ disabled: boolean }>(({ theme, disabled }) =>
  mq({
    ...theme.button,
    background: disabled ? COLORS.NEUTRAL.WARM['200'] : COLORS.YELLOW,
    color: disabled ? COLORS.NEUTRAL.WARM['400'] : COLORS.PURPLE['800'],
    margin: ['26px 0px', '26px auto', '26px 0px'],
  }),
)

const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 200px;

  img {
    margin-right: 12px;
    height: 24px;
    width: 24px;
  }
`

const customParagraphStyles = {
  margin: '25px 0 16px 0',
  textAlign: 'left',
  width: '100%',
  fontSize: 16,
  marginTop: 0,
}

const customConfirmationTextStyles = {
  ...customParagraphStyles,
  margin: '0',
}

const WaitListForm = ({
  data: {
    agreementCheckboxErrorHint,
    agreementCheckboxDescription: { checkboxLabel },
    confirmationMessage,
    ctaButtonText,
    description,
    emailFieldLabel,
    emailFieldErrorHint,
    emailFieldPlaceholder,
    listId,
    contentful_id,
    __typename,
  },
}: IProps) => {
  const [isSubmitted, setSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [fields, setFields] = useState<IFields>({
    email: {
      id: 'email',
      value: '',
      isDirty: false,
    },
    agreement: {
      id: 'agreement',
      value: 'false',
      isDirty: false,
    },
  })

  const sendButtonClickedEvent = useSendButtonClickedEvent()

  const handleChange = (e: IMockedChangeEvent) => {
    const { id, value } = e.target

    setFields(curr => ({
      ...curr,
      [id as keyof IFields]: {
        ...curr[id as keyof IFields],
        value: value,
        isDirty: true,
      },
    }))
  }

  const handleBlur = (e: IMockedChangeEvent) => {
    const { id } = e.target
    setFields(curr => ({
      ...curr,
      [id as keyof IFields]: { ...curr[id as keyof IFields], isDirty: true },
    }))
  }

  const isEmailValid = useMemo(() => {
    return isValidEmail(fields.email.value)
  }, [fields])

  const isAgreementValid = useMemo(() => {
    return /^true$/i.test(fields.agreement.value)
  }, [fields])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    if (isEmailValid && isAgreementValid) {
      sendButtonClickedEvent({
        entry_id: contentful_id ?? 'N/A',
        contentful_type: __typename ?? 'N/A',
        button_text: ctaButtonText ?? 'N/A',
      })

      const myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')

      const raw = JSON.stringify({
        email: fields.email.value,
        list_id: listId,
      })

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
      }

      fetch(`${process.env.MAILCHIMP_FUNCTION_URL}?code=${process.env.MAILCHIMP_FUNCTION_KEY}`, requestOptions)
        .then(() => {
          setIsLoading(false)
          setSubmitted(true)
        })
        .catch(() => {
          setSubmitted(true)
          setIsLoading(false)
        })
    }
  }

  return (
    <ComponentWrapper>
      <Paragraph styleOverrides={customParagraphStyles}>{description}</Paragraph>
      {isSubmitted ? (
        <ConfirmationWrapper>
          <img src={checkIcon} alt="check icon" />
          <Paragraph styleOverrides={customConfirmationTextStyles}>{confirmationMessage}</Paragraph>
        </ConfirmationWrapper>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Input
            id={fields.email.id}
            label={emailFieldLabel}
            value={fields.email.value}
            placeholder={emailFieldPlaceholder}
            errorHint={emailFieldErrorHint}
            hasError={!isEmailValid && fields.email.isDirty}
            onChange={handleChange}
            required
            onBlur={handleBlur}
          />
          <Checkbox
            id={fields.agreement.id}
            label={checkboxLabel}
            errorHint={agreementCheckboxErrorHint}
            hasError={!isAgreementValid && fields.agreement.isDirty}
            checked={isAgreementValid}
            contentfulId={contentful_id}
            typeName={__typename}
            onChange={handleChange}
            required
          />
          <SubmitButton type="submit" value={ctaButtonText} disabled={isLoading} />
        </Form>
      )}
    </ComponentWrapper>
  )
}

export default WaitListForm
