import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'

import mq from '../styles/breakpoints'
import { COLORS } from '@borrowell/bw-styles'
import { formatSectionId } from '../helpers/utils'

import { Button } from '../components/Button'
import { Dropdown, IDropdownOption } from '../components/Dropdown'
import { Section } from '../components/Section'
import { Heading } from '../components/typography'
import { IPageNav } from '../graphql/sections/pageNav'
import { ILink } from '../graphql/modules/link'
import facepaint from 'facepaint'
import { usePathname } from '../context/PathnameProvider'

interface IProps {
  data: IPageNav
}

interface IAllLinks {
  links: ILink[]
  styles?: facepaint.BaseArg | undefined
  pathname: string
  contentfulId: string | null
  typeName: string | null
}

const titleStyles = {
  alignSelf: 'center',
  padding: ['0 20px', '20px 30px', '0 184px'],
}

const DesktopandTabletPageNav = styled.div`
  ${mq({
    display: ['none', 'flex', 'flex'],
    width: '100%',
    flexDirection: ['row', 'row', 'column'],
    alignItems: ['', 'center', 'center'],
    justifyContent: ['', 'space-between', 'space-between'],
    padding: ['', '', '26px 12px'],
  })}
`

const Links = styled.ul`
  ${mq({
    display: 'flex',
    width: '100%',
    flexDirection: ['column', 'row', 'row'],
    justifyContent: ['', 'center', 'center'],
    alignItems: ['', 'center', 'center'],
    padding: ['', '20px', '25px 0'],
    flexWrap: ['', 'wrap', 'wrap'],
    listStyle: 'none',
    margin: 0,
  })}
`

const LinkWrapper = styled.li`
  ${mq({
    display: 'flex',
    width: ['100%', 'auto', 'auto'],
    height: '48px',
    alignItems: ['', 'center', 'center'],
    margin: ['', '10px', '10px'],
  })}
`

const buttonStyles = {
  height: '45px',
}

const MobilePageNav = styled.div`
  ${mq({
    display: ['flex', 'none', 'none'],
    height: '100%',
    width: '100%',
  })}
`

const AllLinks: React.FC<IAllLinks> = ({
  links,
  styles,
  pathname,
  contentfulId,
  typeName,
}) => {
  return (
    <>
      {links.map(({ link, linkText, linkName, rel }, index) => {
        const highlightStyles =
          pathname === link
            ? {
                ...styles,
                ':hover': undefined,
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: COLORS.PURPLE['700'],
              }
            : styles

        return (
          <LinkWrapper key={`${index}-${linkText}`}>
            <Button
              key={index}
              to={link}
              rel={rel}
              name={linkName}
              theme="SECONDARY"
              size="MEDIUM"
              styleOverrides={highlightStyles}
              contentfulId={contentfulId}
              typeName={typeName}
            >
              {linkText}
            </Button>
          </LinkWrapper>
        )
      })}
    </>
  )
}

const PageNavSection: React.FC<IProps> = ({
  data: {
    title,
    navbarLinks: links,
    screenReaderLabel,
    contentful_id,
    __typename,
  },
}) => {
  const handleDropdownClick = ({ value }: IDropdownOption) => {
    navigate(value as string)
  }
  const sectionId = formatSectionId(title)
  const pathname = usePathname()

  let selectedOption: IDropdownOption = {
    label: links[0].linkText,
    value: links[0].link,
  }
  const options = links.map(({ link, linkText }) => {
    const result: IDropdownOption = { value: link, label: linkText }

    if (pathname === link) {
      selectedOption = result
    }
    return result
  })

  return (
    <Section title={title}>
      <Heading as="h2" styleOverrides={titleStyles}>
        {title}
      </Heading>
      <DesktopandTabletPageNav>
        <Links>
          <AllLinks
            links={links}
            styles={buttonStyles}
            pathname={pathname}
            contentfulId={contentful_id}
            typeName={__typename}
          />
        </Links>
      </DesktopandTabletPageNav>
      <MobilePageNav>
        <Dropdown
          id={`${sectionId}-select`}
          options={options}
          selectedOption={selectedOption}
          handleSelection={handleDropdownClick}
          label={screenReaderLabel}
          isVisibleLabel={false}
        />
      </MobilePageNav>
    </Section>
  )
}

export default PageNavSection
