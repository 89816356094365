import React, { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import { mq } from '../styles'
import { COLORS } from '@borrowell/bw-styles'
import { IProductComparisonTable } from '../graphql/sections/productComparisonTable'
import { IKnackLoanProduct } from '../graphql/modules/knackProducts'

import { Section } from '../components/Section'
import { Heading } from '../components/typography'
import { Image } from '../components/Image'
import { Button } from '../components/Button'
import { extractParamsFromUrl } from '../helpers/urlFunctions'
import { paramsService } from '../services/paramsService'

interface IProductComparisonTableProps {
  data: IProductComparisonTable
}

interface ILoanTable {
  products: IKnackLoanProduct[]
  contentfulId: string | null
  typeName: string | null
}

const Table = styled.table`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
    borderCollapse: 'separate',
    borderSpacing: 0,
    borderRadius: '8px',
  })}
`

const THead = styled.thead`
  ${mq({
    display: ['none', 'none', 'flex'],
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    border: 'none',
    backgroundColor: COLORS.NEUTRAL.COOL['100'],
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  })}
`

const TBody = styled.tbody`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  })}
`

const commonCellStyles = {
  display: 'flex',
  width: '17%',
  textAlign: 'center',
  fontFamily: 'lato',
  fontWeight: '400',
  fontSize: '19px',
  justifySelf: 'stretch',
  alignItems: ['flex-start', '', 'center'],
  justifyContent: 'center',
  flex: ['', '', 1],
}

const Th = styled.th`
  ${mq({
    border: 'none',
    borderBottom: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
    borderRadius: '0',
    ...commonCellStyles,
    ':first-of-type': {
      width: '28%',
      borderTopLeftRadius: '8px',
    },
    ':last-of-type': {
      width: '21%',
      borderTopRightRadius: '8px',
    },
  })}
`

const TrDesktop = styled.tr`
  ${mq({
    display: ['none', '', 'flex'],
    flexBasis: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:nth-last-of-type(2) > td': {
      border: 'none',
    },
  })}
`

const TrMobileTablet = styled.tr`
  ${mq({
    display: ['flex', '', 'none'],
    flexDirection: ['column', 'row'],
    flexBasis: '100%',
    ':last-of-type > td': {
      borderBottom: 'none',
    },
  })}
`

const Td = styled.td`
  ${mq({
    ...commonCellStyles,

    width: '17%',
    ':first-of-type': {
      width: ['100%', '40%', '28%'],
      padding: ['0 25px', '20px 15px', 'initial'],
      borderBottom: ['none', `1px solid ${COLORS.NEUTRAL.COOL['300']}`, `1px solid ${COLORS.NEUTRAL.COOL['300']}`],
    },
    ':last-of-type': {
      width: ['100%', '60%', '21%'],
      flex: 1,
      padding: ['15px', '', 0],
    },
  })}
`
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ItemRow = styled.div`
  ${mq({
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    width: ['100%', '90%'],
    margin: 'auto',
    fontSize: '16px',
    textAlign: 'left',
    marginBottom: '10px',
    'div:first-of-type': {
      width: ['50%', '25%'],
      color: COLORS.NEUTRAL.COOL['600'],
    },
    'div:last-of-type': {
      width: ['50%', '55%'],
    },
  })}
`
const ImageWrapper = styled.div`
  ${mq({
    //minWidth: ['70%', '85%'],
    maxWidth: ['70%', '180px'],
    border: ['none', `1px solid ${COLORS.NEUTRAL.COOL['100']}`, 'none'],
    borderRadius: '8px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    height: ['120px', '120px', '150px'],
  })}
`

const titleStyles = {
  marginBottom: '48px',
}

const imageStyles = {
  width: '100%',
  maxWidth: '150px',
  margin: 0,
  padding: 0,
  height: 'fit-content',
}

const buttonStyles = {
  display: 'flex',
  whiteSpace: undefined,
  width: undefined,
  padding: '12px 40px',
  marginTop: ['20px', 0, 0],
}

const LoanTable: React.FC<ILoanTable> = ({ products, contentfulId, typeName }) => {
  const [transformedProducts, setTransformedProducts] = useState(products)
  useEffect(() => {
    const transformProducts = () => {
      const transformedProducts = products.map(product => {
        const {
          link: { url: backupLink },
          marketingSiteLink,
        } = product
        let url = marketingSiteLink?.url ?? backupLink
        if (url.includes('{{mktg_site_session_id}}')) {
          url = paramsService.formatUrlWithMarketingSiteSessionId(url)
        }
        const [baseUrl, baseParams] = url.split('?')
        const paramsObject = extractParamsFromUrl(baseParams)
        const sanitizedParams = Object.entries(paramsObject)
          .filter(([, value]) => value && !value.includes('{CID}'))
          .map(([key, value]) => `${key}=${value}`)
        product.link.url = sanitizedParams.length > 0 ? `${baseUrl}?${sanitizedParams.join('&')}` : baseUrl
        return product
      })

      setTransformedProducts(transformedProducts)
    }
    transformProducts()
  }, [products])
  return (
    <Table>
      <THead>
        <TrDesktop>
          <Th>Provider</Th>
          <Th>Loan Amount</Th>
          <Th>Interest Rate</Th>
          <Th>Term</Th>
          <Th>{''}</Th>
        </TrDesktop>
      </THead>
      <TBody>
        {transformedProducts.map(({ knackId, companyName, image, loanAmount, interestRate, term, link }) => {
          return (
            <React.Fragment key={knackId}>
              <TrDesktop>
                <Td>
                  <ImageWrapper>
                    <Image
                      fluid={image.childImageSharp.gatsbyImageData}
                      alt={companyName}
                      customStyles={imageStyles}
                      objectFit="contain"
                    />
                  </ImageWrapper>
                </Td>
                <Td>{loanAmount}</Td>
                <Td>{interestRate}</Td>
                <Td>{term}</Td>
                <Td>
                  <Button
                    styleOverrides={buttonStyles}
                    theme="PRIMARY"
                    size="MEDIUM"
                    to={link.url}
                    name={'loan-table-button-click'}
                    ariaLabel={`Apply now for ${companyName} Loan`}
                    rel="sponsored"
                    contentfulId={contentfulId}
                    typeName={typeName}
                  >
                    Apply Now
                  </Button>
                </Td>
              </TrDesktop>
              <TrMobileTablet>
                <Td>
                  <ImageWrapper>
                    <Image
                      fluid={image.childImageSharp.gatsbyImageData}
                      alt={companyName}
                      customStyles={imageStyles}
                      objectFit="contain"
                    />
                  </ImageWrapper>
                </Td>
                <Td>
                  <ColumnWrapper>
                    <ItemRow>
                      <div>Loan Amount</div>
                      <div>{loanAmount}</div>
                    </ItemRow>
                    <ItemRow>
                      <div>Interest Rate</div>
                      <div>{interestRate}</div>
                    </ItemRow>
                    <ItemRow>
                      <div>Term</div>
                      <div>{term}</div>
                    </ItemRow>
                    <Button
                      styleOverrides={buttonStyles}
                      to={link.url}
                      name={'loan-table-button-click'}
                      rel="sponsored"
                      contentfulId={contentfulId}
                      typeName={typeName}
                    >
                      Apply Now
                    </Button>
                  </ColumnWrapper>
                </Td>
              </TrMobileTablet>
            </React.Fragment>
          )
        })}
      </TBody>
    </Table>
  )
}

export const ProductComparisonTableSection: React.FC<IProductComparisonTableProps> = ({
  data: { displayTitle, title, productVertical, knackProducts, contentful_id, __typename },
}) => {
  const table = useMemo(() => {
    if (productVertical === 'Loan vertical')
      return <LoanTable products={knackProducts} contentfulId={contentful_id} typeName={__typename} />
    return null
  }, [productVertical, knackProducts])
  return (
    <Section title={title}>
      <Heading as="h2" styleOverrides={titleStyles}>
        {displayTitle}
      </Heading>
      {table}
    </Section>
  )
}
