import React from 'react'
import styled from '@emotion/styled'

import { mq } from '../styles'
import { COLORS } from '@borrowell/bw-styles'

import { Image } from '../components/Image'
import { Section } from '../components/Section'
import { Heading, Paragraph } from '../components/typography'
import { IMultipleBlocks } from '../graphql/sections/multipleBlocks'

interface IProps {
  data: IMultipleBlocks
}

/**
 * Custom Styled-Components
 */

const BlocksContainer = styled.ul`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'row', 'row'],
    justifyContent: ['center', 'space-between', 'center'],
    margin: 0,
    alignItems: ['center', 'flex-start', 'flex-start'],
    flexWrap: 'wrap',
    maxWidth: ['100%', '500px', '1200px'],
  })}
`

const Block = styled.li`
  ${mq({
    display: 'flex',
    flexDirection: ['row', 'column', 'column'],
    justifyContent: 'center',
    alignItems: 'center',
    width: ['100%', '230px', '304px'],
    margin: ['0 0 20px 0', '0 2px 57px 2px', '0 13px 50px 13px'],
    '& p:last-child': {
      marginBottom: '20px',
    },
  })}
`

const customSectionStyles = {
  padding: ['80px 22px', '80px 36px 55px 36px', '150px 60px 100px 60px'],
}

/**
 * Custom Styles for External Components
 */

const imageStyles = {
  width: '48px',
  height: '48px',
  minWidth: '48px',
  margin: ['0 17px 0 0', '0', '0'],
}

const paragraphStyles = {
  fontSize: ['16px', '24px', '24px'],
  lineHeight: ['1.5', '1.25', '1.25'],
  textAlign: ['left', 'center', 'center'],
  fontWeight: 'bold',
  color: COLORS.WHITE,
  margin: ['0', '14px 0 0 0', '20px 0'],
  width: '100%',
}

const titleStyles = {
  lineHeight: ['1.31', '1.11', '1.11'],
  color: COLORS.WHITE,
}

const descriptionStyles = {
  fontSize: ['16px', '18px', '18px'],
  lineHeight: ['1.5', '1.44', '1.44'],
  textAlign: ['left', 'left', 'center'],
  color: COLORS.WHITE,
  margin: ['25px 0', '25px 0 50px 0', '20px 0 50px 0'],
  maxWidth: 'none',
}

export const MultipleBlocksSection: React.FC<IProps> = ({
  data: {
    title: sectionTitle,
    description: { description: sectionDescription },
    multipleBlocks: blocks,
    backgroundImageDesktop,
    backgroundImageMobile,
    backgroundImageTablet,
  },
}) => {
  const backgroundImageDesktopUrl = backgroundImageDesktop.image.gatsbyImageData
  const backgroundImageTabletUrl = backgroundImageTablet.image.gatsbyImageData
  const backgroundImageMobileUrl = backgroundImageMobile.image.gatsbyImageData

  const background = [
    backgroundImageMobileUrl,
    backgroundImageTabletUrl,
    backgroundImageDesktopUrl,
  ]

  return (
    <Section
      title={sectionTitle}
      background={{ background }}
      customStyles={customSectionStyles}
    >
      <Heading as="h2" styleOverrides={titleStyles}>
        {sectionTitle}
      </Heading>
      <Paragraph styleOverrides={descriptionStyles}>
        {sectionDescription}
      </Paragraph>
      <BlocksContainer>
        {blocks.map(({ title, image }) => (
          <Block key={title}>
            {image && (
              <Image
                fluid={image.image.gatsbyImageData}
                alt={image.alternateText.alternateText}
                customStyles={imageStyles}
              />
            )}
            <Paragraph styleOverrides={paragraphStyles}>{title}</Paragraph>
          </Block>
        ))}
      </BlocksContainer>
    </Section>
  )
}
