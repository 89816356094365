import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'
import { COLORS } from '@borrowell/bw-styles'

import Section from '../components/Section'
import { Heading, Markdown } from '../components/typography'
import { Link } from '../components/Link'
import { Button } from '../components/Button'
import { type } from 'os'

interface IProps {
  dataJson: string
  contentfulId: string | null
  typeName: string | null
}

interface IJobPostingsData {
  title: string
  url: string
  apiUrl: string
  text: {
    title: string
    body: string
  }
  button: {
    label: string
    url: string
  }
}

interface IDepartmentContainer {
  title: string
  postings: { title: string; url: string }[]
  contentfulId: string | null
  typeName: string | null
}

interface IJobs {
  [x: string]: { title: string; url: string }[]
}

interface ITransformer {
  jobs: {
    department: string
    title: string
    url: string
  }[]
}

const transformer = (response: ITransformer): IJobs =>
  response &&
  response.jobs &&
  response.jobs.reduce((acc: IJobs, cur) => {
    if (acc[cur.department]) acc[cur.department].push(cur)
    else acc[cur.department] = [cur]
    return acc
  }, {})

const h4Styles = {
  color: COLORS.PURPLE['700'],
  ':hover,:active,:focus': {
    color: COLORS.NEUTRAL.COOL['900'],
  },
}

const DepartmentWrapper = styled.div`
  margin: 30px 0;
`

const DepartmentContainer: React.FC<IDepartmentContainer> = ({
  title,
  postings = [],
  contentfulId,
  typeName,
}) => {
  return (
    <DepartmentWrapper>
      <Heading as="h3">{title}</Heading>
      {postings.map(({ title: job, url }) => {
        return (
          <Link
            key={job}
            to={url}
            contentfulId={contentfulId}
            typeName={typeName}
          >
            <Heading as="h4" styledAs="h5" styleOverrides={h4Styles}>
              {job}
            </Heading>
          </Link>
        )
      })}
    </DepartmentWrapper>
  )
}

const defaultSectionStyles = {
  padding: ['40px 0', '60px 0', '45px 0'],
}

const TextContent = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: ['320px', '540px', '600px'],
  })}
`

const JobPostingsSection: React.FC<IProps> = ({
  dataJson,
  contentfulId,
  typeName,
}) => {
  const data = JSON.parse(dataJson).componentData as IJobPostingsData
  const { title, apiUrl, text, button } = data

  // load jobs from provided Workable API URL
  const [jobs, setJobs] = useState<IJobs>({})
  useEffect(() => {
    if (apiUrl)
      fetch(apiUrl)
        .then(response => response.json())
        .then(transformer)
        .then(setJobs)
  }, [apiUrl])

  return (
    <Section title={title} customStyles={defaultSectionStyles}>
      <TextContent>
        <Heading as="h2">{text.title}</Heading>
        <Markdown
          withSuperscript
          contentfulId={contentfulId}
          typeName={typeName}
        >
          {text.body}
        </Markdown>
      </TextContent>
      <Heading as="h2">{title}</Heading>
      {Object.entries(jobs).map(([title, postings]) => (
        <DepartmentContainer
          key={title}
          title={title}
          postings={postings}
          contentfulId={contentfulId}
          typeName={typeName}
        />
      ))}
      <Button
        to={button.url}
        name="Current Openings Button"
        contentfulId={contentfulId}
        typeName={typeName}
      >
        {button.label}
      </Button>
    </Section>
  )
}

export default JobPostingsSection
