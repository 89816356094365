import { useTreatments, useSplitTreatments } from '@splitsoftware/splitio-react'
import { useAnonymousIdContext } from '../context/AnonymousIdContextProvider'
import { useMemo } from 'react'

import { CONTROL_TREATMENT, ExperimentDetails } from './types'

type Attributes = Record<string, string | number | boolean | Array<string | number>>

// Reference on how to add new experiments on https://borrowell.atlassian.net/browse/GROW-1058

export const useExperiment = <TreatmentType extends string>(
  experimentName: string,
  attributes?: Attributes,
): ExperimentDetails<TreatmentType> => {
  const { treatments: treatmentsWithConfig } = useSplitTreatments({ names: [experimentName], attributes })
  const { isTimedOut: isAnonymousIdTimedOut } = useAnonymousIdContext()

  const experiment = useMemo(() => {
    const treatmentWithConfig = treatmentsWithConfig[experimentName]
    let treatment = CONTROL_TREATMENT as TreatmentType
    // Use provided treatment only if anonymousId is set
    if (!isAnonymousIdTimedOut) {
      treatment = (treatmentWithConfig?.treatment ?? CONTROL_TREATMENT) as TreatmentType
    }
    const config = treatmentWithConfig?.config
      ? (JSON.parse(treatmentWithConfig.config) as Record<string, string>)
      : null
    return { treatment, config }
  }, [treatmentsWithConfig, experimentName, isAnonymousIdTimedOut])

  return experiment
}
