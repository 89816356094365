import { COLORS } from '@borrowell/bw-styles'
import styled from '@emotion/styled'
import React, { useCallback } from 'react'

import { IMockedChangeEvent } from './commonTypes'
import { mq } from '../styles'
import { Markdown } from './typography'

interface ICheckbox {
  id: string
  hasError?: boolean
  required?: boolean
  checked?: boolean
  label: string
  errorHint: string
  contentfulId: string | null
  typeName: string | null
  onChange: (e: IMockedChangeEvent) => void
}

const CheckboxWrapper = styled.div`
  margin: 26px 0px;
`

const CheckboxRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 6px 0px;
`

const InputWrapper = styled.div(
  mq({
    height: '16px',
    width: '16px',
    marginRight: '10px',
  }),
)

const _Checkbox = styled.input(
  mq({
    height: '16px',
    width: '16px',
    paddingTop: '2px',
  }),
)

const customLabel = {
  fontFamily: 'Lato',
  margin: 0,
  fontSize: '14px',
  lineHeight: '16px',
  textAlign: 'left',
}

const ErrorHint = styled.span(({ theme }) =>
  mq({
    ...theme.paragraph,
    color: COLORS.RED['700'],
    fontSize: ['12px', '16px', '16px'],
    fontStyle: 'italic',
  }),
)

export const Checkbox = ({
  hasError,
  id,
  label,
  errorHint,
  required,
  checked,
  contentfulId,
  typeName,
  onChange,
}: ICheckbox) => {
  const handleChange = useCallback(
    ev => {
      onChange({
        target: {
          ...ev.target,
          id,
          value: !checked ? 'true' : 'false',
        },
      })
    },
    [checked],
  )

  return (
    <CheckboxWrapper>
      <CheckboxRow>
        <InputWrapper>
          <_Checkbox
            type="checkbox"
            id={id}
            required={!!required}
            checked={!!checked}
            onChange={handleChange}
          />
        </InputWrapper>
        <Markdown
          customStyles={{ paragraph: customLabel, link: customLabel }}
          withSuperscript
          contentfulId={contentfulId}
          typeName={typeName}
        >
          {label}
        </Markdown>
      </CheckboxRow>
      {hasError && <ErrorHint>{errorHint}</ErrorHint>}
    </CheckboxWrapper>
  )
}
