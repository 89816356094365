import React from 'react'
import { IGenericContent } from '../graphql/sections/genericContent'
import JobPostingsSection from './JobPostingsSection'
import { MortgageCalculatorSection } from './MortgageCalculatorSection'
import PersonalLoanCalculatorSection from './PersonalLoanCalculatorSection'

interface IProps {
  data: IGenericContent
}

const componentMap = {
  JobPostingsSection: JobPostingsSection,
  MortgageCalculatorSection: MortgageCalculatorSection,
  PersonalLoanCalculatorSection: PersonalLoanCalculatorSection,
}

export const GenericContentRenderer: React.FC<IProps> = ({ data }) => {
  const { data: componentData } = data
  const componentKey = componentData.component

  // Layout components that should render on every page will never go through the page adapter, and therefore should not be rendered
  // from the GenericContentRenderer, but should instead query for their components directly. See components/SnackBar
  if (componentKey === 'SnackBarSection') return null
  const Component = componentMap[componentKey] || null
  if (!Component) return null

  return (
    <Component dataJson={componentData.internal.content} contentfulId={data.contentful_id} typeName={data.__typename} />
  )
}
