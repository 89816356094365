import React from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'

import { Image } from '../components/Image'
import { Link } from '../components/Link'
import { Section } from '../components/Section'
import { Heading, Paragraph } from '../components/typography'
import { IPartners } from '../graphql/sections/partners'
import { IPartner } from '../graphql/modules/partner'

interface IProps {
  data: IPartners
}

const PartnersContainer = styled.ul`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: 0,
    listStyle: 'none',
  })}
`

const PartnerItem = styled.li`
  ${mq({
    margin: 0,
  })}
`

const partnerLogoStyle = {
  width: '240px',
  margin: '10px',
}

const titleStyles = {
  fontSize: ['23px', '44px', '44px'],
  alignSelf: 'center',
}

const paragraphStyles = {
  lineHeight: '1.5',
  textAlign: ['left', 'center', 'center'],
  margin: ['10px 0 20px 0', '25px 0 34px 0', '25px 0 55px 0'],
}

const PartnerImages: React.FC<{ partners: IPartner[] }> = ({ partners }) => {
  return (
    <>
      {partners.map(({ title, image, link, contentful_id, __typename }) => {
        const altText = image?.alternateText.alternateText ?? ''
        const fixedImg = image?.image.gatsbyImageData

        return link ? (
          <PartnerItem key={title}>
            <Link
              name={link.linkName}
              to={link.link}
              ariaLabel={link.linkText}
              rel={link.rel}
              contentfulId={contentful_id}
              typeName={__typename}
            >
              <Image
                fixed={fixedImg}
                alt={altText}
                customStyles={{ partnerLogoStyle }}
              />
            </Link>
          </PartnerItem>
        ) : (
          <PartnerItem key={title}>
            <Image
              fixed={fixedImg}
              alt={altText}
              customStyles={{ partnerLogoStyle }}
            />
          </PartnerItem>
        )
      })}
    </>
  )
}

export const PartnersSection: React.FC<IProps> = ({
  data: { block, partners, title: sectionTitle },
}) => {
  const { title, text: sectionText } = block

  return (
    <Section title={sectionTitle}>
      <Heading as="h2" styleOverrides={titleStyles}>
        {title}
      </Heading>
      <Paragraph styleOverrides={paragraphStyles}>
        {sectionText?.text}
      </Paragraph>
      <PartnersContainer>
        <PartnerImages partners={partners} />
      </PartnersContainer>
    </Section>
  )
}
