const personalLoanCalculatorService = {
  getPayment({
    amount,
    rate,
    loanTerm: termString,
  }: {
    amount: string
    rate: string
    loanTerm: string
  }) {
    const loanAmount = parseFloat(amount.replace(/,|\$/g, ''))
    const newRate = parseFloat(rate.replace('%', ''))
    const loanTerm = parseInt(termString)

    if (isAnyInputInvalid(loanAmount, newRate, loanTerm)) {
      return ''
    }

    const r = newRate / 12 / 100
    const newPayment = (r * loanAmount) / (1 - Math.pow(1 + r, -loanTerm))

    return formatResponse(newPayment)
  },

  getTotalInterestPaid({
    amount,
    rate,
    loanTerm: termString,
  }: {
    amount: string
    rate: string
    loanTerm: string
  }) {
    const loanAmount = parseFloat(amount.replace(/,|\$/g, ''))
    const newRate = parseFloat(rate.replace('%', ''))
    const loanTerm = parseInt(termString)

    if (isAnyInputInvalid(loanAmount, newRate, loanTerm)) {
      return ''
    }

    const r = newRate / 12 / 100
    const newPayment = (r * loanAmount) / (1 - Math.pow(1 + r, -loanTerm))
    const interestPaid = newPayment * loanTerm - loanAmount

    return formatResponse(interestPaid)
  },

  formatCurrency: (value: string | number) => {
    let strValue = value.toString().replace(/[a-z]/gi, '')
    const lastChar = strValue[strValue.length - 1]

    if (lastChar === '.') {
      if (strValue.substring(0, strValue.length - 1).includes('.')) {
        return strValue.substring(0, strValue.length - 1)
      }
    }

    strValue =
      strValue.indexOf('.') >= 0 && strValue.indexOf('.') <= strValue.length - 4
        ? `${strValue.split('.')[0]}.${strValue.split('.')[1].substring(0, 2)}`
        : strValue

    return strValue.replaceAll(',', '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
}

/*****************************
 * INTERNAL UTILS            *
 ****************************/

const isAnyInputInvalid = (...args: number[]) =>
  args.some(argument => isNaN(argument))

const formatResponse = (response: number) =>
  `$   ${Number(response.toFixed(2)).toLocaleString()}`

export default personalLoanCalculatorService
