import React from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'
import { COLORS } from '@borrowell/bw-styles'
import { BulletIcon, ICard } from '../graphql/modules/card'

import checkIcon from '../images/checkmark-green.svg'

import { Heading } from './typography'
import { Button } from './Button'
import { Image } from './Image'
import { List } from './typography/List'

interface IProps {
  data: ICard
}

interface IPropsListSection {
  list: string[]
  icon: BulletIcon
}

const Wrapper = styled.div`
  ${mq({
    width: ['100%', '48%', '30%'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    border: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '30px',
  })}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const CtaContent = styled.div`
  display: flex;
  margin: auto auto 0 auto;
  justify-content: center;
  width: 100%;
`

const imageStyles = {
  margin: '0 auto',
  width: '160px',
  height: '145px',
}

const subtitleStyles = {
  margin: '9px auto 20px auto',
}

const buttonStyles = {
  margin: '0 auto',
  //display: 'flex',
  width: '100%',
}

const listStyles = {
  fontFamily: 'Lato',
  fontSize: '22px',
  margin: '0 50px 18px 50px',
}

const UL = styled.ul`
  ${mq({
    fontFamily: 'Lato',
    fontSize: '22px',
    margin: '15px 45px',
    listStyle: 'none',
    paddingLeft: '20px',
    textIndent: '-20px',
    'li:before': {
      content: `url('${checkIcon}')`,
      marginRight: '12px',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  })}
`

const TextSection: React.FC<IPropsListSection> = ({ list, icon }) => {
  const WrapperComponent = icon === BulletIcon.Check ? UL : List
  return (
    <WrapperComponent
      styleOverrides={listStyles}
      ordered={icon === BulletIcon.Numbers}
    >
      {list.map(item => (
        <li key={item}>{item}</li>
      ))}
    </WrapperComponent>
  )
}

export const Card: React.FC<IProps> = ({ data }) => {
  const {
    displayTitle,
    subtitle,
    bulletIcon,
    list,
    image: {
      alternateText: { alternateText },
      image: { gatsbyImageData },
    },
    cta: { linkText, link, rel },
    contentful_id,
    __typename,
  } = data
  return (
    <Wrapper>
      <Content>
        <Image
          fixed={gatsbyImageData}
          alt={alternateText}
          customStyles={imageStyles}
        />
        <Heading as="h3">{displayTitle}</Heading>
        <Heading as="h5" styleOverrides={subtitleStyles}>
          {subtitle}
        </Heading>
        <TextSection icon={bulletIcon} list={list} />
      </Content>
      <CtaContent>
        <Button
          to={link}
          style={buttonStyles}
          styleOverrides={buttonStyles}
          rel={rel}
          contentfulId={contentful_id}
          typeName={__typename}
        >
          {linkText}
        </Button>
      </CtaContent>
    </Wrapper>
  )
}
