import { graphql } from 'gatsby'
import { IImage } from './image'
import { ILink } from './link'

export const card = graphql`
  fragment CardFragment on ContentfulCard {
    contentful_id
    displayTitle
    subtitle
    bulletIcon
    list
    image {
      ...ImageFragment
    }
    cta {
      ...LinkFragment
    }
  }
`

export enum BulletIcon {
  Check = 'Check',
  Bullet = 'Bullet',
  Numbers = 'Numbers',
}
export interface ICard {
  __typename: 'ContentfulCard'
  contentful_id: string
  displayTitle: string
  subtitle: string
  bulletIcon: BulletIcon
  list: string[]
  image: IImage
  cta: ILink
}
