import { useCallback } from 'react'
import { ButtonClickedEventProperties } from '../../types/analytics/analyticsEventProperties'
import { AnalyticsEvents } from '../../types/analytics/analyticsEvents.enum'
import { useSendGTMEvent } from './useSendGTMEvent'
import { useSendSegmentEvent } from './useSendSegmentEvent'

export const useSendButtonClickedEvent = () => {
  const { sendSegmentTrackEvent } = useSendSegmentEvent<ButtonClickedEventProperties>()
  const sendGTMEvent = useSendGTMEvent<ButtonClickedEventProperties>()

  const sendButtonClickedEvent = useCallback(
    (buttonClickedEventProperties: ButtonClickedEventProperties) => {
      sendSegmentTrackEvent(AnalyticsEvents.BUTTON_CLICKED, buttonClickedEventProperties)
      sendGTMEvent(AnalyticsEvents.BUTTON_CLICKED, buttonClickedEventProperties)
    },
    [sendSegmentTrackEvent, sendGTMEvent],
  )

  return sendButtonClickedEvent
}
