import React from 'react'
import styled from '@emotion/styled'

import { mq } from '../styles/breakpoints'
import {
  Heading,
  Superscript,
  Markdown,
  Paragraph,
} from '../components/typography'
import { AppStoreBadges } from '../components/AppStoreBadges'
import { IHeroSection } from '../graphql/sections/hero'
import { Section } from '../components/Section'
import { Button } from '../components/Button'
import { Image } from '../components/Image'
import WaitListForm from './WaitListForm'
import { COLORS } from '@borrowell/bw-styles'
import { getCookieData } from '../helpers/utils'

interface IProps {
  data: IHeroSection
}

/**
 * Custom Styled-Components
 */

const ContentInfo = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: ['center', 'center', 'flex-start'],
    maxWidth: ['', '', '55%'],
    margin: ['0 0 26px 0', '0 0 37px 0', '0'],
  })}
`

const TopBadgeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0px 0px 15px;
`

/**
 * Custom Styles for External Components
 */

const customSectionStyles = {
  flexDirection: ['column', 'column', 'row'],
  justifyContent: ['center', 'center', 'space-around'],
  alignItems: 'center',
  padding: ['0 22px 25px 22px', '50px 40px 100px 35px', '16px 50px 106px 60px'],
}

const verticalImage = {
  width: ['207px', '303px', '303px'],
  height: ['432px', '603px', '603px'],
}

const horizontalImage = {
  width: ['320px', '560px', '500px'],
  height: ['432px', '603px', '603px'],
}

const topBadgeTextStyles = {
  margin: '0px 5px 0px 0px',
  textAlign: 'left',
  fontSize: 14,
}

const TOP_BADGE_MAX_HEIGHT = 36
const TOP_BADGE_MAX_WIDTH = 200
const topBadgeImageWrapperStyles = {
  width: 'auto',
  height: '100%',
  maxHeight: `${TOP_BADGE_MAX_HEIGHT}px`,
  maxWidth: `${TOP_BADGE_MAX_WIDTH}px`,
}

const topBadgeImageStyles = {
  width: 'auto',
  height: '100%',
  maxHeight: `${TOP_BADGE_MAX_HEIGHT}px`,
  maxWidth: `${TOP_BADGE_MAX_WIDTH}px`,
}

const titleStyles = {
  textAlign: 'left',
  maxWidth: ['none', '528px', '855px'],
}

const paragraphStyles = {
  textAlign: 'left',
  margin: ['10px 0 30px 0', '25px 0 32px 0', '22px 0 32px 0'],
}

const customAppStoreSectionStyles = {
  padding: ['15px 0 10px 0', '15px 0 0 0 ', '15px 0 15px 0'],
  justifyContent: 'flex-start',
  width: '100%',
}

const HeroSection: React.FC<IProps> = ({ data }) => {
  const {
    contentInfo,
    contentful_id,
    title: sectionTitle,
    showAppStoreDownloadButtons,
    backgroundImageDesktop,
    backgroundImageTablet,
    backgroundImageMobile,
    extraContent,
    topBadge,
    topBadgeColor,
    topBadgeImage,
  } = data
  const { title } = contentInfo
  const text = contentInfo.text?.text

  const buttonLink = contentInfo.link?.link
  const buttonText = contentInfo.link?.linkText
  const buttonName = contentInfo.link?.linkName
  const buttonRel = contentInfo.link?.rel
  const buttonContentfulId = contentInfo.link?.contentful_id ?? null
  const buttonTypeName = contentInfo.link?.__typename ?? null

  const linkCookieData = getCookieData(contentInfo.link)

  const fluidImg = contentInfo.image?.image.gatsbyImageData
  const altText = contentInfo.image?.alternateText.alternateText ?? ''

  const backgroundImageDesktopUrl = backgroundImageDesktop.image.gatsbyImageData
  const backgroundImageTabletUrl = backgroundImageTablet.image.gatsbyImageData
  const backgroundImageMobileUrl = backgroundImageMobile.image.gatsbyImageData

  const background = [
    backgroundImageMobileUrl,
    backgroundImageTabletUrl,
    backgroundImageDesktopUrl,
  ]

  const topBadgeImageUrl = topBadgeImage?.image.gatsbyImageData
  const topBadgeImageAltText = topBadgeImage?.alternateText.alternateText ?? ''

  const waitListFormData = extraContent?.find(
    content => content.__typename === 'ContentfulWaitListForm',
  )

  return (
    <Section
      title={sectionTitle}
      background={{
        background,
        isAboveFold: true,
        styleOverrides: { backgroundPosition: 'center center' },
      }}
      customStyles={customSectionStyles}
    >
      <ContentInfo>
        {topBadge && (
          <TopBadgeContainer>
            <Paragraph
              styleOverrides={{
                ...topBadgeTextStyles,
                color: topBadgeColor ?? COLORS.NEUTRAL.COOL['600'],
              }}
            >
              {topBadge}
            </Paragraph>
            <Image
              fluid={topBadgeImageUrl}
              alt={topBadgeImageAltText}
              customStyles={topBadgeImageWrapperStyles}
              customImageStyles={topBadgeImageStyles}
              objectFit="contain"
            />
          </TopBadgeContainer>
        )}
        <Heading styleOverrides={titleStyles}>
          <Superscript>{title}</Superscript>
        </Heading>
        {!!text && (
          <Markdown
            customStyles={{ paragraph: paragraphStyles }}
            withSuperscript
            contentfulId={buttonContentfulId}
            typeName={buttonTypeName}
          >
            {text}
          </Markdown>
        )}

        {waitListFormData && <WaitListForm data={waitListFormData} />}

        {buttonLink && buttonName && !waitListFormData && (
          <Button
            data-cy={`hero-button-${contentful_id}`}
            to={buttonLink}
            name={buttonName}
            rel={buttonRel}
            style={{ padding: '0' }}
            theme="PRIMARY"
            size="LARGE"
            linkCookieData={linkCookieData}
            contentfulId={buttonContentfulId}
            typeName={buttonTypeName}
          >
            {buttonText}
          </Button>
        )}
        {showAppStoreDownloadButtons && (
          <AppStoreBadges customStyles={customAppStoreSectionStyles} />
        )}
      </ContentInfo>
      {fluidImg && (
        <Image
          fluid={fluidImg}
          alt={altText}
          customStyles={
            /**
             * @todo Revisit this solution in the future. It might be worth it to build a separate component to deal with
             * different aspect ratios or even having a better defined convention.
             */
            fluidImg.width / fluidImg.height >= 1
              ? horizontalImage
              : verticalImage
          }
        />
      )}
    </Section>
  )
}

export default HeroSection
