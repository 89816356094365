import React from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'
import { COLORS } from '@borrowell/bw-styles'

import { Heading, Paragraph } from '../components/typography'
import { Image } from '../components/Image'
import { Section } from '../components/Section'
import { IReviews } from '../graphql/sections/reviews'
import { IReview } from '../graphql/modules/review'

interface IProps {
  data: IReviews
}

const ReviewsContainer = styled.ul`
  ${mq({
    marginLeft: '0px',
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    justifyContent: 'center',
    alignItems: ['center', 'flex-start', 'flex-start'],
    padding: ['20px 0 0 0', '49px 0 0 0', '39px 0 0 0'],
    flexWrap: ['none', 'none', 'wrap'],
  })}
`

const Review = styled.li`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: ['10px 0', '10px 0', '10px 13px'],
    padding: ['20px', '26px', '26px'],
    boxShadow: `0 20px 38px -20px ${COLORS.PURPLE['200']}`,
    backgroundImage: `linear-gradient(140deg, ${COLORS.PINK['600']}, ${COLORS.PURPLE['700']} 70%)`,
    borderRadius: ['20px', '10px', '10px'],
    maxWidth: ['317px', '528px', '414px'],
    minHeight: ['289px', '233px', '233px'],
    width: ['317px', '528px', '414px'],
    height: ['100%', '233px', '233px'],
  })}
`

const ReviewHeader = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'row', 'row'],
    width: '100%',
    height: ['', '50px', '50px'],
    justifyContent: ['center', 'flex-start', 'flex-start'],
    alignItems: ['center', 'flex-start', 'flex-start'],
  })}
`

const NameAndLocation = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: ['flex-start', 'flex-start', 'flex-start'],
    alignItems: ['center', 'flex-start', 'flex-start'],
    margin: ['10px 0', '0 0 0 10px', '0 0 0 10px'],
    width: '100%',
  })}
`

const ProfilePictureWrapper = styled.div`
  width: 36px;
  height: 36px;
  margin: auto;
`

const RatingWrapper = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ['center', 'flex-end', 'flex-end'],
    width: ['100%', '40%', '40%'],
  })}
`

const ReviewFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const profilePictureStyles = {
  width: '36px',
  height: '36px',
}

const ratingImageStyles = {
  width: '78px',
  height: '15px',
}

const sourceImageStyles = {
  width: '24px',
  height: '24px',
}

const nameTextStyles = {
  margin: '0',
  color: COLORS.WHITE,
  textAlign: ['', '', 'left'],
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  fontSize: ['14px', '14px', '12px'],
  lineHeight: ['1.43', '1.43', '1.67'],
}

const locationTextStyles = {
  margin: '0',
  color: COLORS.WHITE,
  textAlign: ['', '', 'left'],
  textOverflow: 'ellipsis',
  fontSize: '12px',
  lineHeight: '1.5',
}

const reviewTextStyles = {
  maxWidth: ['271px', '462px', '362px'],
  height: ['103px', '80px', '80px'],
  textAlign: ['', '', 'left'],
  margin: ['15px 0 8px 0', '26px 0 13px 0', '20px 0 13px 0'],
  color: COLORS.WHITE,
  flex: 'auto',
  fontSize: ['16px', '18px', '18px'],
  lineHeight: ['1.5', '1.44', '1.5'],
}

const Reviews: React.FC<{ reviewsData: IReview[] }> = ({ reviewsData }) => {
  return (
    <>
      {reviewsData.map(
        ({ profilePicture, name, location, text, rating, source }, index) => {
          const profileImage = profilePicture.image.gatsbyImageData
          const profilePictureAltText =
            profilePicture.alternateText.alternateText
          const reviewText = text.text
          const imageRating = rating.image.gatsbyImageData
          const ratingAltText = rating.alternateText.alternateText
          const imageSource = source.image.gatsbyImageData
          const sourceAltText = source.alternateText.alternateText

          return (
            <Review key={index}>
              <ReviewHeader>
                <ProfilePictureWrapper>
                  <Image
                    fluid={profileImage}
                    alt={profilePictureAltText}
                    customStyles={profilePictureStyles}
                  />
                </ProfilePictureWrapper>
                <NameAndLocation>
                  <Paragraph styleOverrides={nameTextStyles}>{name}</Paragraph>
                  <Paragraph styleOverrides={locationTextStyles}>
                    {location}
                  </Paragraph>
                </NameAndLocation>
                <RatingWrapper>
                  <Image
                    fluid={imageRating}
                    alt={ratingAltText}
                    customStyles={ratingImageStyles}
                  />
                </RatingWrapper>
              </ReviewHeader>
              <Paragraph styleOverrides={reviewTextStyles}>
                {reviewText}
              </Paragraph>
              <ReviewFooter>
                <Image
                  fluid={imageSource}
                  alt={sourceAltText}
                  customStyles={sourceImageStyles}
                />
              </ReviewFooter>
            </Review>
          )
        },
      )}
    </>
  )
}

export const ReviewSection: React.FC<IProps> = ({
  data: { title, reviews },
}) => {
  return (
    <Section title={title}>
      <Heading as="h2">{title}</Heading>
      <ReviewsContainer>
        <Reviews reviewsData={reviews} />
      </ReviewsContainer>
    </Section>
  )
}
