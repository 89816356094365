import React from 'react'

import { IProductDetailsList } from '../graphql/sections/productDetailsList'

import ProductDetails from './ProductDetailsSection'
import Section from '../components/Section'
import { Heading } from '../components/typography'
import CreditCardDetails from './CreditCardDetailsSection'

interface IProps {
  data: IProductDetailsList
}

const ProductDetailsListSection: React.FC<IProps> = ({ data }) => {
  const {
    products: productDetailsList,
    ctaCopy,
    creditCards,
    shouldDisplayTitle,
  } = data
  const sectionTitle = data.title

  return (
    <Section title={sectionTitle}>
      {shouldDisplayTitle && <Heading as="h2">{sectionTitle}</Heading>}
      {productDetailsList?.map(productElement => {
        return (
          <ProductDetails
            ctaCopy={ctaCopy}
            key={productElement.knackId}
            id={productElement.knackId}
            data={productElement}
          />
        )
      })}
      {creditCards?.map(creditCard => {
        return (
          <CreditCardDetails key={creditCard.contentful_id} data={creditCard} />
        )
      })}
    </Section>
  )
}

export default ProductDetailsListSection
