import { COLORS } from '@borrowell/bw-styles'
import styled from '@emotion/styled'
import React from 'react'
import { Button } from '../components/Button'
import { Paragraph } from '../components/typography'
import { IBanner } from '../graphql/sections/banner'
import { getCookieData } from '../helpers/utils'

interface IProps {
  data: IBanner
}

const Banner = styled.div`
  position: sticky;
  top: 0px;
  width: 100%;
  padding: 5px 30px;
  background-color: ${COLORS.TURQUOISE['900']};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`

const paragraphStyles = {
  margin: '0px 5px 0px 0px',
  textAlign: 'left',
  fontSize: '18px',
  boxSizing: 'border-box',
}

const buttonStyles = {
  fontSize: '18px',
  width: '100px',
  backgroundColor: 'none',
  marginTop: '0px',
  height: '44px',
  fontWeight: 'bold',
  lineHeight: 'normal',
  color: COLORS.WHITE,
  margin: '0px',
  ':hover': {
    backgroundColor: 'none',
  },
  textDecoration: 'underline',
}

const BannerSection: React.FC<IProps> = ({ data }) => {
  const { contentful_id, bannerText, bannerButton } = data
  const buttonLink = bannerButton.link
  const buttonText = bannerButton.linkText
  const buttonName = bannerButton.linkName
  const buttonRel = bannerButton.rel
  const buttonContentfulId = bannerButton.contentful_id
  const buttonTypeName = bannerButton.__typename

  const linkCookieData = getCookieData(bannerButton)

  return (
    <Banner>
      <Paragraph
        styleOverrides={{
          ...paragraphStyles,
          color: COLORS.WHITE,
        }}
      >
        {bannerText}
      </Paragraph>
      <Button
        data-cy={`banner-button-${contentful_id}`}
        to={buttonLink}
        rel={buttonRel}
        name={buttonName}
        contentfulId={buttonContentfulId}
        typeName={buttonTypeName}
        styleOverrides={buttonStyles}
        linkCookieData={linkCookieData}
      >
        {buttonText}
      </Button>
    </Banner>
  )
}

export default BannerSection
