import React from 'react'

import { Image } from '../components/Image'

import { IHeroImage } from '../graphql/sections/heroImage'

interface IProps {
  data: IHeroImage
}

const imageStyles = {
  width: '100%',
  height: '100%',
  maxWidth: '1440px',
  margin: 'auto',
}

export const HeroImageSection: React.FC<IProps> = ({
  data: {
    image: {
      alternateText: { alternateText },
      image: { gatsbyImageData },
    },
  },
}) => {
  return (
    <Image
      fluid={gatsbyImageData}
      alt={alternateText}
      customStyles={imageStyles}
    />
  )
}
