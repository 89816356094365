import React from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

import mq from '../styles/breakpoints'
import { useSEOFunctions } from '../context/useSEO'

import { Heading } from '../components/typography'
import Accordion from '../components/Accordion'
import RichText from '../components/RichText'
import Section from '../components/Section'
import { IExpandableFaq, IQuestion } from '../graphql/sections/expandableFaq'

interface IProps {
  data: IExpandableFaq
}

const customSectionStyles = {
  maxWidth: '771px',
}

const QuestionsContainer = styled.div`
  ${mq({
    paddingTop: '40px',
    width: '100%',
    listStyleType: 'none',
    margin: 0,
  })}
`

const richTextStyles = {
  'heading-1': {
    textAlign: 'left',
  },
  'heading-2': {
    textAlign: 'left',
  },
  'heading-3': {
    textAlign: 'left',
  },
  'heading-4': {
    textAlign: 'left',
  },
  'heading-5': {
    textAlign: 'left',
  },
  paragraph: {
    textAlign: 'left',
  },
  'ordered-list': {
    marginTop: '25px',
    marginBottom: '50px',
  },
  'unordered-list': {
    marginTop: '25px',
    marginBottom: '50px',
  },
}

const customSubtitleStyles = { marginTop: '20px' }

function formatStructuredData(questions: IQuestion[]) {
  return questions.map(({ question, answer }) => {
    return { question, answer: documentToHtmlString(JSON.parse(answer.raw)) }
  })
}

export const ExpandableFaq: React.FC<IProps> = ({ data }) => {
  const { title, subtitle, questions } = data

  const addStructuredData = useSEOFunctions()

  const questionList = React.useMemo(
    () =>
      questions.map(({ question, answer, contentful_id: contentfulId }) => {
        const richText = { ...answer, references: [] }
        return (
          <Accordion title={question} key={contentfulId} id={contentfulId}>
            <RichText content={richText} customStyles={richTextStyles} />
          </Accordion>
        )
      }),
    [questions],
  )

  React.useEffect(() => {
    const data = formatStructuredData(questions)
    addStructuredData({ data, type: 'faqData' })
  }, [questions, addStructuredData])

  return (
    <Section title={title} customStyles={customSectionStyles}>
      <Heading as="h2">{title}</Heading>
      <Heading as="h3" styleOverrides={customSubtitleStyles}>
        {subtitle}
      </Heading>
      <QuestionsContainer>{questionList}</QuestionsContainer>
    </Section>
  )
}
