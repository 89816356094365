import { useMemo } from 'react'
import { TypeNames } from '../constants'
import { IPageVariation } from '../graphql/sections/pageVariation'
import { Sections } from '../graphql/sections/types'

export type IPageWithExperimentSectionsSetupData = {
  experiment: string
  treatment: string | null
  pageSections: Sections[]
}

export type IPageWithExperimentSectionsSetupResult = {
  sections: Sections[]
  treatmentSelected: string | null
  shouldRedirectTo404Page: boolean
}

export const usePageWithExperimentSectionsSetup = ({
  experiment,
  pageSections,
  treatment,
}: IPageWithExperimentSectionsSetupData): IPageWithExperimentSectionsSetupResult => {
  return useMemo((): { sections: Sections[]; treatmentSelected: string | null; shouldRedirectTo404Page: boolean } => {
    const pageVariations: IPageVariation[] = pageSections.filter<IPageVariation>(
      (section): section is IPageVariation => section.__typename === TypeNames.CONTENTFUL_PAGE_VARIATION,
    )

    if (pageVariations.length === 0) {
      // redirect to the 404 page
      return { sections: [], treatmentSelected: null, shouldRedirectTo404Page: true }
    }

    let treatmentPageVariation = pageVariations.find(variation => variation.experimentTreatment === treatment)

    // If no matching treatment is found, use the first available Page Variation
    if (!treatmentPageVariation) {
      treatmentPageVariation = pageVariations[0]
    }

    return {
      sections: treatmentPageVariation.sections,
      treatmentSelected: treatmentPageVariation.experimentTreatment,
      shouldRedirectTo404Page: false,
    }
  }, [experiment, pageSections, treatment])
}
