import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'
import { COLORS, toRGBAString } from '@borrowell/bw-styles'
import { ILinksListData } from '../graphql/sections/linksList'
import { ILink } from '../graphql/modules/link'

import { Heading } from '../components/typography'
import Section from '../components/Section'
import { Link } from '../components/Link'

interface IProps {
  data: ILinksListData
}

interface IHashMap {
  [key: string]: ILink[]
}

interface ITabsSection {
  tabs: string[]
  selectedTab: string
  setSelectedTab: (tabTitle: string) => void
}

interface ILinkList {
  links: ILink[]
  isSelected?: boolean
  contentfulId: string | null
  typeName: string | null
}

const CONTAINER_STYLES = {
  flexDirection: 'column',
  border: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
  borderRadius: '8px',
  width: '100%',
  marginTop: '40px',
}

const ContainerDesktop = styled.div`
  ${mq({
    display: ['none', '', 'flex'],
    ...CONTAINER_STYLES,
  })}
`
const ContainerTabletMobile = styled.div`
  ${mq({
    display: ['flex', '', 'none'],
    ...CONTAINER_STYLES,
  })}
`

const TabsContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    wdith: '100%',
  })}
`

const Tab = styled.button(
  ({
    isSelected,
    isFirstTab,
  }: {
    isSelected?: boolean
    isFirstTab?: boolean
  }) =>
    mq({
      textAlign: 'center',
      border: 'none',
      borderRight: ['none', '', `1px solid ${COLORS.NEUTRAL.COOL['300']}`],
      borderBottom: isSelected
        ? [`1px solid ${COLORS.NEUTRAL.COOL['300']}`, '', 'none']
        : `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
      flex: 1,
      fontFamily: 'Lato',
      fontSize: '18px',
      fontWeight: isSelected ? 'bold' : 'normal',
      color: isSelected ? COLORS.PRIMARY['400'] : COLORS.NEUTRAL.COOL['900'],
      padding: '10px',
      backgroundColor: isSelected
        ? 'white'
        : toRGBAString(COLORS.NEUTRAL.COOL['100'], 0.6),
      ':hover,:focus,:active': {
        cursor: 'pointer',
        color: COLORS.PRIMARY['600'],
        backgroundColor: isSelected ? 'white' : COLORS.PURPLE['050'],
        fontWeight: 600,
      },

      ':first-of-type': {
        borderTopLeftRadius: ['initial', '', '8px'],
      },
      ':last-of-type': {
        borderTopRightRadius: ['none', 'none', '8px'],
        borderRight: 'none',
        borderTop: [
          isFirstTab ? 'none' : `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
          isFirstTab ? 'none' : `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
          `none`,
        ],
        borderBottom: !isSelected
          ? ['none', 'none', `1px solid ${COLORS.NEUTRAL.COOL['300']}`]
          : '',
      },
      ':focus': {
        shadow: 'none',
        backgroundColor: 'white',
        outline: 'none',
      },
    }),
)

const MobileWrapper = styled.div(
  ({ isSelected }: { isSelected: boolean }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  :first-of-type > button {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  ${
    !isSelected
      ? `:last-child > button {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: none;
    }
  `
      : ''
  }
`,
)

const LinkContainer = styled.ul(({ isSelected }: { isSelected: boolean }) =>
  mq({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    maxHeight: isSelected ? '100vh' : '0',
    borderBottom: isSelected
      ? [
          `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
          `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
          'none',
        ]
      : 'none',
    overflow: 'hidden',
    ':last-child': {
      borderBottom: 'none',
    },
    marginInlineStart: 0,
    marginBlockStart: 0,
    marginBlockEnd: 0,
  }),
)

const LinkWrapper = styled.li`
  ${mq({
    display: 'flex',
    justifyContent: 'center',
    padding: '15px',
    width: ['50%', '', '16.6666667%'],
    marginBottom: '0',
  })}
`

const linkTextStyles = {
  width: '100%',
}

const customLinkStyles = {
  fontFamily: 'lato',
  width: '100%',
  textAlign: 'center',
  padding: '12px 0',
  ':hover, :focus, :active': {
    color: COLORS.PRIMARY['500'],
    fontWeight: '600',
    backgroundColor: toRGBAString(COLORS.PRIMARY['050'], 0.25),
    borderRadius: '4px',
    '& > *': {
      color: COLORS.PRIMARY['500'],
      fontWeight: '600',
    },
  },
}

const Tabs: React.FC<ITabsSection> = ({
  tabs,
  selectedTab,
  setSelectedTab,
}) => {
  return (
    <TabsContainer>
      {tabs.map(tab => {
        return (
          <Tab
            key={tab}
            isSelected={tab === selectedTab}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </Tab>
        )
      })}
    </TabsContainer>
  )
}

const LinksList: React.FC<ILinkList> = ({
  links,
  isSelected = true,
  contentfulId,
  typeName,
}) => {
  return (
    <LinkContainer isSelected={isSelected}>
      {links.map(link => {
        return (
          <LinkWrapper key={link.link}>
            <Link
              to={link.link}
              rel={link.rel}
              styleOverrides={customLinkStyles}
              textStyles={linkTextStyles}
              contentfulId={contentfulId}
              typeName={typeName}
            >
              {link.linkText}
            </Link>
          </LinkWrapper>
        )
      })}
    </LinkContainer>
  )
}

const LinksListSection: React.FC<IProps> = ({ data }) => {
  const { displayTitle: title } = data
  const { linksSections } = data
  const { contentful_id, __typename } = data

  const { linkTabs, linkListsMap } = useMemo(() => {
    const linkTabs: string[] = []
    const linkListsMap: IHashMap = {}
    linksSections.forEach(({ displayTitle, links }) => {
      linkTabs.push(displayTitle)
      linkListsMap[displayTitle] = links
    })
    return { linkTabs, linkListsMap }
  }, [linksSections])

  const [selectedTab, setSelectedTab] = useState(linkTabs[0])

  return (
    <Section title={title}>
      <Heading as="h2">{title}</Heading>
      <ContainerDesktop>
        <Tabs
          tabs={linkTabs}
          selectedTab={selectedTab}
          setSelectedTab={tabTitle => setSelectedTab(tabTitle)}
        />
        <LinksList
          links={linkListsMap[selectedTab]}
          contentfulId={contentful_id}
          typeName={__typename}
        />
      </ContainerDesktop>
      <ContainerTabletMobile>
        {linkTabs.map((linkTab, index) => {
          const isSelected = linkTab === selectedTab
          return (
            <MobileWrapper key={linkTab} isSelected={isSelected}>
              <Tab
                onClick={() => setSelectedTab(linkTab)}
                isSelected={isSelected}
                isFirstTab={index === 0}
              >
                {linkTab}
              </Tab>
              <LinksList
                links={linkListsMap[linkTab]}
                isSelected={isSelected}
                contentfulId={contentful_id}
                typeName={__typename}
              />
            </MobileWrapper>
          )
        })}
      </ContainerTabletMobile>
    </Section>
  )
}

export default LinksListSection
