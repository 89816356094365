const mortgageCalculatorService = {
  /**
   * @todo
   * @description ADD DESCRIPTION
   */
  getPayment({ amount, downPayment, term, rate, amortization }) {
    let mortgageAmount = amount.replace(/,|\$/g, '')
    const payment = downPayment.replace(/,|\$/g, '')
    const newRate = rate.replace('%', '')

    if (
      isAnyInputInvalid(mortgageAmount, payment, term, newRate, amortization)
    ) {
      return ''
    }

    mortgageAmount = mortgageAmount - payment
    const r = newRate / 12 / 100
    const numberOfMonths = amortization * 12
    const newPayment =
      (r * mortgageAmount) / (1 - Math.pow(1 + r, -numberOfMonths))

    return formatResponse(newPayment)
  },

  /**
   * @todo
   * @description ADD DESCRIPTION
   */
  getInterestPaid({ amount, downPayment, term, rate, amortization }) {
    let mortgageAmount = amount.replace(/,|\$/g, '')
    const payment = downPayment.replace(/,|\$/g, '')
    const newRate = rate.replace('%', '')

    if (
      isAnyInputInvalid(mortgageAmount, payment, term, newRate, amortization)
    ) {
      return ''
    }

    mortgageAmount = mortgageAmount - payment
    const r = newRate / 12 / 100
    const numberOfMonths = amortization * 12
    const numberOfMonthsInTerm = term * 12
    const newPayment =
      (r * mortgageAmount) / (1 - Math.pow(1 + r, -numberOfMonths))
    const interestPaid =
      ((mortgageAmount * r - newPayment) *
        (Math.pow(1 + r, numberOfMonthsInTerm) - 1)) /
        r +
      newPayment * numberOfMonthsInTerm

    return formatResponse(interestPaid)
  },

  /**
   * @todo
   * @description ADD DESCRIPTION
   */
  getPrincipalPaid({ amount, downPayment, term, rate, amortization }) {
    let mortgageAmount = amount.replace(/,|\$/g, '')
    const payment = downPayment.replace(/,|\$/g, '')
    const newRate = rate.replace('%', '')

    if (
      isAnyInputInvalid(mortgageAmount, payment, term, newRate, amortization)
    ) {
      return ''
    }

    mortgageAmount = mortgageAmount - payment
    const r = newRate / 12 / 100
    const numberOfMonths = amortization * 12
    const numberOfMonthsInTerm = term * 12
    const newPayment =
      (r * mortgageAmount) / (1 - Math.pow(1 + r, -numberOfMonths))
    const interestPaid =
      ((mortgageAmount * r - newPayment) *
        (Math.pow(1 + r, numberOfMonthsInTerm) - 1)) /
        r +
      newPayment * numberOfMonthsInTerm
    const principalPaid = newPayment * numberOfMonthsInTerm - interestPaid

    return formatResponse(principalPaid)
  },

  /**
   * @todo
   * @description ADD DESCRIPTION
   */
  getBalance({ amount, downPayment, term, rate, amortization }) {
    let mortgageAmount = amount.replace(/,|\$/g, '')
    const payment = downPayment.replace(/,|\$/g, '')
    const newRate = rate.replace('%', '')

    if (
      isAnyInputInvalid(mortgageAmount, payment, term, newRate, amortization)
    ) {
      return ''
    }

    mortgageAmount = mortgageAmount - payment
    const r = newRate / 12 / 100
    const numberOfMonths = amortization * 12
    const numberOfMonthsInTerm = term * 12
    const newPayment =
      (r * mortgageAmount) / (1 - Math.pow(1 + r, -numberOfMonths))

    const interestPaid =
      ((mortgageAmount * r - newPayment) *
        (Math.pow(1 + r, numberOfMonthsInTerm) - 1)) /
        r +
      newPayment * numberOfMonthsInTerm
    const principalPaid = newPayment * numberOfMonthsInTerm - interestPaid
    const balance = mortgageAmount - principalPaid

    return formatResponse(balance)
  },

  /**
   * @todo
   * @description ADD DESCRIPTION
   */
  getMortgageAmount({ amount, downPayment }) {
    const mortgageAmount = amount.replace(/,|\$/g, '')
    const payment = downPayment.replace(/,|\$/g, '')

    if (isAnyInputInvalid(mortgageAmount, payment)) {
      return ''
    }

    return formatResponse(mortgageAmount - payment)
  },

  /**
   * @todo
   * @description ADD DESCRIPTION
   */
  getTotalInterestPaid({ amount, downPayment, term, rate, amortization }) {
    let mortgageAmount = amount.replace(/,|\$/g, '')
    const payment = downPayment.replace(/,|\$/g, '')
    const newRate = rate.replace('%', '')

    if (
      isAnyInputInvalid(mortgageAmount, payment, term, newRate, amortization)
    ) {
      return ''
    }

    mortgageAmount = mortgageAmount - payment

    const r = newRate / 12 / 100
    const N = amortization * 12
    const newPayment = (r * mortgageAmount) / (1 - Math.pow(1 + r, -N))
    const interestPaid = newPayment * N - mortgageAmount

    return formatResponse(interestPaid)
  },
}

/*****************************
 * INTERNAL UTILS            *
 ****************************/

const isAnyInputInvalid = (...args) => args.some(argument => isNaN(argument))

const formatResponse = response =>
  `$   ${Number(response.toFixed(2)).toLocaleString()}`

export default mortgageCalculatorService
