import React from 'react'

import { SEO } from '../components/SEO'
import { AlternatingImageTextBlocksSection } from '../containers/AlternatingImageTextBlockSection'
import { AppStoresSection } from '../containers/AppStoresSection'
import BannerSection from '../containers/BannerSection'
import { BlogPostSection } from '../containers/BlogPostSection'
import { BlogsSection } from '../containers/BlogsSection'
import { ExpandableFaq } from '../containers/ExpandableFaqSection'
import { GenericContentRenderer } from '../containers/GenericContentRenderer'
import { HeroImageSection } from '../containers/HeroImageSection'
import HeroSection from '../containers/HeroSection'
import LinksListSection from '../containers/LinksListSection'
import { MultipleBlocksSection } from '../containers/MultipleBlocksSection'
import OneBlockSection from '../containers/OneBlockSection'
import { OneLinkSection } from '../containers/OneLinkSection'
import PageNavSection from '../containers/PageNavSection'
import { PartnersSection } from '../containers/PartnersSection'
import { ProductComparisonTableSection } from '../containers/ProductComparisonTableSection'
import ProductDetailsListSection from '../containers/ProductDetailsListSection'
import { ProductsSection } from '../containers/ProductsSection'
import { ReviewSection } from '../containers/ReviewsSection'
import RichTextSection from '../containers/RichTextSection'
import { StatisticsSection } from '../containers/StatisticsSection'
import { TeamMembersSection } from '../containers/TeamMembersSection'
import ThreeBlocksSection from '../containers/ThreeBlocksSection'
import TrustSection from '../containers/TrustSection'
import { Sections } from '../graphql/sections/types'

interface IProps {
  data: Sections
}

export const PageSection: React.FC<IProps> = ({ data }) => {
  switch (data.__typename) {
    case 'ContentfulAlternatingImageTextBlock':
      return <AlternatingImageTextBlocksSection data={data} />
    case 'ContentfulAppStores':
      return <AppStoresSection data={data} />
    case 'ContentfulBanner':
      return <BannerSection data={data} />
    case 'ContentfulBlogs':
      return <BlogsSection data={data} />
    case 'ContentfulBlog':
      return <BlogPostSection data={data} />
    case 'ContentfulExpandableFaq':
      return <ExpandableFaq data={data} />
    case 'ContentfulGenericContent':
      return <GenericContentRenderer data={data} />
    case 'ContentfulHero':
      return <HeroSection data={data} />
    case 'ContentfulHeroImage':
      return <HeroImageSection data={data} />
    case 'ContentfulLinksListSection':
      return <LinksListSection data={data} />
    case 'ContentfulMetadata':
      return <SEO data={data} />
    case 'ContentfulMultipleBlocks':
      return <MultipleBlocksSection data={data} />
    case 'ContentfulOneBlock':
      return <OneBlockSection data={data} />
    case 'ContentfulOneLink':
      return <OneLinkSection data={data} />
    case 'ContentfulPageNav':
      return <PageNavSection data={data} />
    case 'ContentfulPartners':
      return <PartnersSection data={data} />
    case 'ContentfulProductComparisonTable':
      return <ProductComparisonTableSection data={data} />
    case 'ContentfulProductDetailsList':
      return <ProductDetailsListSection data={data} />
    case 'ContentfulProducts':
      return <ProductsSection data={data} />
    case 'ContentfulReviews':
      return <ReviewSection data={data} />
    case 'ContentfulRichText':
      return <RichTextSection data={data} />
    case 'ContentfulStatistics':
      return <StatisticsSection data={data} />
    case 'ContentfulTeamMembers':
      return <TeamMembersSection data={data} />
    case 'ContentfulThreeBlocks':
      return <ThreeBlocksSection data={data} />
    case 'ContentfulTrust':
      return <TrustSection data={data} />
    default:
      return null
  }
}
