import styled from '@emotion/styled'
import React, { useMemo, useState } from 'react'

import { COLORS } from '@borrowell/bw-styles'
import { HEADER_HEIGHT_VALUES } from '../constants'
import mq from '../styles/breakpoints'

import { useTableOfContents } from '../context/TableOfContentsProvider'
import { ExpandIcon } from './ExpandIcon'
import { AnchorLink } from './Link'
import { Heading, List, ListElement } from './typography'

interface IWithScrollPos {
  isMobile?: boolean
}

interface IIsOpen {
  isOpen: boolean
}

const Wrapper = styled.div(() =>
  mq({
    display: 'flex',
    maxWidth: ['100%', '100%', '288px'],
    flexDirection: 'column',
    border: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
    borderRadius: '8px',
    position: 'sticky',
    top: `${HEADER_HEIGHT_VALUES.DESKTOP + 20}px`,
    width: '100%',
  }),
)

const TableHeader = styled.div<IIsOpen>(({ isOpen }) =>
  mq({
    backgroundColor: COLORS.NEUTRAL.COOL['100'],
    borderBottom: isOpen
      ? `1px solid ${COLORS.NEUTRAL.COOL['300']}`
      : '1px solid transparent',
    borderRadius: isOpen ? '8px 8px 0 0' : '8px',
    padding: '16px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    transition: 'border-bottom 0.4s, border-radius 0.4s',
  }),
)

const BodySection = styled.div<IIsOpen>(({ isOpen }) =>
  mq({
    padding: isOpen ? '20px 27px' : '0 27px',
    maxHeight: [
      isOpen ? '100vh' : '0vh',
      isOpen ? '100vh' : '0vh',
      isOpen ? '68vh' : '0vh',
    ],
    overflowY: isOpen ? 'auto' : 'hidden',
    transition: 'max-height 0.3s, padding 0.4s',
    borderRadius: '8px',
  }),
)

const titleStyles = {
  fontSize: '20px',
  textAlign: 'left',
}

const listItemStyles = {
  marginBottom: '8px',
  wordWrap: 'break-word',
}

const linkStyles = {
  fontWeight: 'normal',
}

export const TableOfContents: React.FC<IWithScrollPos> = ({
  isMobile = false,
}) => {
  const [isOpen, setIsOpen] = useState(!isMobile)
  const tableOfContents = useTableOfContents()

  const linkList = useMemo(() => {
    if (tableOfContents)
      return (
        <List>
          {tableOfContents.map(({ label, anchor }) => (
            <ListElement key={anchor} styleOverrides={listItemStyles}>
              <AnchorLink
                anchorId={anchor}
                textStyles={linkStyles}
                data-cy={`link-${anchor}`}
              >
                {label}
              </AnchorLink>
            </ListElement>
          ))}
        </List>
      )
  }, [tableOfContents])

  return (
    <Wrapper>
      <TableHeader
        isOpen={isOpen}
        onClick={() => setIsOpen(curr => !curr)}
        role="button"
        data-cy="table-of-contents-menu-button"
      >
        <Heading as="h5" styleOverrides={titleStyles}>
          Article Contents
        </Heading>
        <ExpandIcon isOpen={isOpen} />
      </TableHeader>
      <BodySection isOpen={isOpen}>{linkList}</BodySection>
    </Wrapper>
  )
}
