import styled from '@emotion/styled'
import get from 'lodash/get'
import React, { useEffect, useReducer, useState } from 'react'

import { COLORS } from '@borrowell/bw-styles'
import facepaint from 'facepaint'
import { Button } from '../components/Button'
import { Input } from '../components/Input'
import Section from '../components/Section'
import { Heading } from '../components/typography'
import { useAriaLiveAnnouncement } from '../hooks/useAriaLiveAnnouncement'
import personalLoanCalculatorService from '../services/personalLoanCalculatorService'
import mq from '../styles/breakpoints'

interface IProps {
  dataJson: string
  contentfulId: string | null
  typeName: string | null
}

export interface IPersonalLoanCalculator {
  title: string
}

const customSectionStyles = {
  width: ['100%', '50%', '50%'],
  margin: '20px auto',
  padding: ['0 20px', '0 20px', '0'],
}

const InputSection = styled.dd`
  ${mq({
    width: ['100%', '100%', '40%'],
    display: ['block', 'block', 'inline-block'],
    border: '0px',
    borderRadius: '8px',
    height: ['50px', '50px', '48px'],
    fontSize: ['16px', '16px', '20px'],
    fontFamily: 'Lato',
    paddingLeft: ['0px', '0px', '40px'],
    textAlign: ['center', 'center', 'left'],
    margin: 0,
  })}
`

const Subtitle = styled.dt`
  ${mq({
    width: ['100%', '100%', '50%'],
    fontSize: ['16px', '16px', '20px'],
    color: COLORS.NEUTRAL.COOL['600'],
    fontFamily: 'Lato',
    fontWeight: '400',
    display: ['block', 'block', 'inline-block'],
    marginBottom: ['10px', '10px', '0px'],
    textAlign: ['center', 'center', 'left'],
    verticalAlign: 'top',
  })}
`

const Container = styled.dl<{ customStyles?: facepaint.BaseArg }>(({ customStyles }) =>
  mq({
    width: ['100%', '100%', '80%'],
    margin: ['0', '0', '0 auto'],
    paddingTop: '20px',
    textAlign: 'center',
    ...customStyles,
  }),
)

const Form = styled.form`
  ${mq({
    width: ['100%', '', '80%'],
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  })}
`

const buttonStyles = {
  borderRadius: ['30px', '', '30px'],
  fontSize: ['18px', '', '18px'],
  fontWeight: ['900', '', '900'],
  lineHeight: ['1.44', '', '1.44'],
}
type State = {
  amount: string
  rate: string
  loanTerm: string
  amountValid: boolean
  rateValid: boolean
  loanTermValid: boolean
  totalPayment: string
  interestPaid: string
}
const initialState: State = {
  // Input Values
  amount: '10,000',
  rate: '15',
  loanTerm: '36',
  // Error States
  amountValid: true,
  rateValid: true,
  loanTermValid: true,
  // Computed Values
  totalPayment: '$   0',
  interestPaid: '$   0',
}

const customTitleStyles = {
  margin: '0 0 1.45rem',
}

const calculatePersonalLoanValues = ({ amount, rate, loanTerm }: Pick<State, 'amount' | 'rate' | 'loanTerm'>) => {
  const calculatedValues = {
    totalPayment: personalLoanCalculatorService.getPayment({
      amount,
      rate,
      loanTerm,
    }),
    interestPaid: personalLoanCalculatorService.getTotalInterestPaid({
      amount,
      rate,
      loanTerm,
    }),
  }

  return calculatedValues
}

type LocalState = {
  amountValid: boolean
  rateValid: boolean
  loanTermValid: boolean
}

const initialLocalState: LocalState = {
  amountValid: true,
  rateValid: true,
  loanTermValid: true,
}

/**
 * Validation Regexes
 */
const AMOUNT_REGEX = /^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\.[0-9][0-9])?$/
const RATE_REGEX = /^\d+(\.\d{1,2})?$/
const NUMBER_ONLY_REGEX = /^\d+$/

type InputKeys = 'amount' | 'rate' | 'loanTerm'
type ValidatorKey = 'amountValid' | 'rateValid' | 'loanTermValid'
type Action =
  | {
      type: ValidatorKey
      payload: string
    }
  | {
      type: 'calculate'
      payload?: undefined
    }
  | {
      type: InputKeys
      payload: string
    }

const reducer = (state: State, action: Action) => {
  let value
  switch (action.type) {
    case 'calculate':
      return {
        ...state,
        ...calculatePersonalLoanValues(state),
      }
    case 'amount':
      value = personalLoanCalculatorService.formatCurrency(action.payload)
      break
    case 'loanTerm':
    case 'rate':
      value = action.payload.replace(/[a-z.,]/gi, '')
      break
    default:
      break
  }

  if (value) return { ...state, [action.type]: value }
  return state
}

type InputConfig = {
  label: string
  stateKey: InputKeys
  type: string
  icon?: string
  iconPosition?: 'left' | 'right'
  errorMessage?: string
}

const INPUTS_CONFIG: InputConfig[] = [
  {
    label: 'Amount',
    stateKey: 'amount',
    type: 'number',
    icon: '$',
    iconPosition: 'left',
    errorMessage: 'Amount must be a positive number',
  },
  {
    label: 'Rate',
    stateKey: 'rate',
    type: 'number',
    icon: '%',
    iconPosition: 'right',
    errorMessage: 'Rate must be a positive whole number',
  },
  {
    label: 'Loan Term (Months)',
    stateKey: 'loanTerm',
    type: 'number',
    errorMessage: 'Loan term must be a positive whole number',
  },
]

const validate = (value: string, regex: RegExp) => {
  return value.match(regex) !== null && parseFloat(value) > 0
}

const PersonalLoanCalculatorSection: React.FC<IProps> = ({ dataJson, contentfulId, typeName }) => {
  const data = JSON.parse(dataJson).componentData as IPersonalLoanCalculator
  const [state, dispatch] = useReducer(reducer, initialState)
  const title = get(data, 'title')
  const [shouldAnnounce, setShouldAnnounce] = useState(false)
  const [validatorState, setValidatorState] = useState<LocalState>(initialLocalState)

  const { liveRegion, ariaAnnounce } = useAriaLiveAnnouncement({
    options: { delay: 500 },
  })

  /**
   * @todo fix the submission for the form
   */
  const handleSubmit: React.FormEventHandler = event => {
    //event will only be defined is the form submit is triggered, the button does not pass event.
    if (event) event.preventDefault()

    const amountValid = validate(personalLoanCalculatorService.formatCurrency(state.amount), AMOUNT_REGEX)
    const rateValid = validate(state.rate, RATE_REGEX)
    const loanTermValid = validate(state.loanTerm, NUMBER_ONLY_REGEX)

    if (amountValid && rateValid && loanTermValid) {
      dispatch({ type: 'calculate' })
      setShouldAnnounce(true)
    }

    setValidatorState({ amountValid, rateValid, loanTermValid })
  }

  const renderInputs = (inputConfigs: InputConfig[] = []) =>
    inputConfigs.map(({ label, stateKey, errorMessage, icon, iconPosition }) => (
      <Input
        key={stateKey}
        label={label}
        id={stateKey}
        value={state[stateKey]}
        icon={icon}
        iconPosition={iconPosition}
        onChange={e => {
          dispatch({
            type: stateKey,
            payload: e.target.value,
          })
          if (!validatorState[`${stateKey}Valid`]) {
            setValidatorState({
              ...validatorState,
              [`${stateKey}Valid`]: true,
            })
          }
        }}
        hasError={!validatorState[`${stateKey}Valid`]}
        errorHint={errorMessage || `Invalid ${stateKey}`}
      />
    ))

  useEffect(() => {
    if (shouldAnnounce) {
      ariaAnnounce(`Monthly Payment: ${state.totalPayment}\nInterest Cost for Term: ${state.interestPaid}`)
      setShouldAnnounce(false)
    }
  }, [shouldAnnounce])

  return (
    <Section title={title} customStyles={customSectionStyles}>
      <Form onSubmit={handleSubmit}>
        <Heading as="h2" styledAs="h4" styleOverrides={customTitleStyles}>
          Personal Loan Info
        </Heading>
        {renderInputs(INPUTS_CONFIG)}

        <Button
          onClick={handleSubmit}
          name="personalLoanSubmit"
          styleOverrides={buttonStyles}
          contentfulId={contentfulId}
          typeName={typeName}
        >
          Calculate
        </Button>
      </Form>
      <hr style={{ width: '100%' }} />
      <Container>
        <Heading as="h3" styledAs="h4" styleOverrides={customTitleStyles}>
          Personal Loan Payment
        </Heading>
        {liveRegion}
        <Subtitle>Monthly Payment</Subtitle>
        <InputSection id="payment">{state.totalPayment}</InputSection>
        <Subtitle>Interest Cost for Term</Subtitle>
        <InputSection id="interest_paid">{state.interestPaid}</InputSection>
      </Container>
    </Section>
  )
}

export default PersonalLoanCalculatorSection
