import React from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'

import { Heading, Paragraph } from '../components/typography'
import { Image } from '../components/Image'
import { Button } from '../components/Button'
import Section from '../components/Section'
import { IAlternatingImageTextBlocksSection } from '../graphql/sections/alternatingImageTextBlock'
import { ICopy } from '../graphql/modules/copy'

interface IProps {
  data: IAlternatingImageTextBlocksSection
}

const BlockWrapper = styled.div`
  ${mq({
    display: 'flex',
    width: '100%',
    flexDirection: ['column', 'column', 'row'],
    justifyContent: 'space-evenly',
    alignItems: ['center', 'flex-start', 'center'],
    margin: ['0 0 40px 0', '0 0 50px 0', '22px 0'],
    '&:nth-of-type(even) > div:first-of-type': {
      order: ['', '', '2'],
    },
  })}
`

const TextColumn = styled.div`
  ${mq({
    flex: '1 0 50%',
    margin: 'auto auto',
    width: ['100%', '66%', 'auto'],
  })}
`
const ButtonWrapper = styled.div`
  margin-top: 25px;
`

const titleStyles = {
  textAlign: 'left',
}

const imageStyles = {
  height: 'auto',
  alignSelf: 'center',
  flex: '1 0 50%',
  width: ['100%', '66%', 'auto'],
  margin: ['0 0 20px 0', '0 0 20px 0', '0'],
}

const paragraphStyles = {
  textAlign: 'left',
  margin: '25px 0 0 0',
}

const buttonStyles = {
  margin: ['0 auto', '0 auto 0 0 ', '0 auto 0 0 '],
}

const headerStyles = {
  margin: ['0 0 40px 0', '0 0 40px 0', '0'],
}

const sectionStyles = {
  padding: ['35px 22px 0 22px', '50px 36px 0 36px', '50px 60px 0 60px'],
}

const BlockContainer: React.FC<{ block: ICopy }> = ({
  block: { title, text: copy, image, link },
}) => {
  const text = copy?.text
  const altText = image?.alternateText.alternateText ?? ''
  const fluidImg = image?.image.gatsbyImageData
  const buttonLink = link?.link
  const buttonText = link?.linkText ?? ''
  const buttonName = link?.linkName ?? ''
  const buttonRel = link?.rel
  const buttonContentfulId = link?.contentful_id ?? null
  const buttonTypeName = link?.__typename ?? null

  return (
    <BlockWrapper>
      {!!fluidImg && (
        <Image fluid={fluidImg} alt={altText} customStyles={imageStyles} />
      )}
      <TextColumn>
        <Heading as="h4" styleOverrides={titleStyles}>
          {title}
        </Heading>
        <Paragraph styleOverrides={paragraphStyles}>{text}</Paragraph>
        {!!buttonLink && !!buttonText && (
          <ButtonWrapper>
            <Button
              to={buttonLink}
              name={buttonName}
              styleOverrides={buttonStyles}
              rel={buttonRel}
              contentfulId={buttonContentfulId}
              typeName={buttonTypeName}
            >
              {buttonText}
            </Button>
          </ButtonWrapper>
        )}
      </TextColumn>
    </BlockWrapper>
  )
}

export const AlternatingImageTextBlocksSection: React.FC<IProps> = ({
  data: { title, blocks },
}) => {
  return (
    <Section title={title} customStyles={sectionStyles}>
      <Heading as="h2" styleOverrides={headerStyles}>
        {title}
      </Heading>
      {blocks.map(block => (
        <BlockContainer key={block.title} block={block} />
      ))}
    </Section>
  )
}
