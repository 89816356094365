import { useMemo } from 'react'
import { TypeNames } from '../constants/enums'
import { Sections } from '../graphql/sections/types'

const usePageWithoutExperimentSectionsSetup = (pageSections: Sections[]): Sections[] => {
  const sectionsFiltered = useMemo(
    () => pageSections.filter(section => section.__typename !== TypeNames.CONTENTFUL_PAGE_VARIATION),
    [pageSections],
  )

  return sectionsFiltered
}

export default usePageWithoutExperimentSectionsSetup
